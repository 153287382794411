import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import ConfirmationPopup from "../Ui Component/ConfirmationPopup";
import { useAuth, useUser } from "@clerk/clerk-react";

// Network configurations
const NETWORKS = {
  REDBELLY: {
    name: "RedBelly Mainnet",
    rpc: "https://governors.mainnet.redbelly.network",
    chainId: 151,
    explorer: "https://redbelly.routescan.io",
  },
  BSC: {
    name: "Binance Smart Chain",
    rpc: "https://bsc-dataseed.binance.org",
    usdtAddress: "0x55d398326f99059fF775485246999027B3197955", // BSC USDT contract
    chainId: 56,
    explorer: "https://bscscan.com",
  },
};

function WithdrawCrypto() {
  const { getToken } = useAuth();
  const { user } = useUser();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedType, setSelectedType] = useState("USDT"); // Changed from USDC to USDT
  const [selectedBasket, setSelectedBasket] = useState(null);
  const [amount, setAmount] = useState("");
  const [withdrawData, setWithdrawData] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [userBalances, setUserBalances] = useState({
    usdc: "0",
    baskets: {},
  });
  const [useCustomAddress, setUseCustomAddress] = useState(false);
  const [customAddress, setCustomAddress] = useState("");
  const [optimaWalletAddress, setOptimaWalletAddress] = useState("");
  const [connectedWalletAddress, setConnectedWalletAddress] = useState("");
  const [isBalanceLoading, setIsBalanceLoading] = useState(true);
  const [selectedNetwork, setSelectedNetwork] = useState(NETWORKS.BSC);
  const [showKycModal, setShowKycModal] = useState(false);
  const [kycMessage, setKycMessage] = useState("");
  const [kycRedirectUrl, setKycRedirectUrl] = useState("");

  // Add effect to get connected wallet address
  React.useEffect(() => {
    if (user?.primaryWeb3Wallet) {
      setConnectedWalletAddress(user.primaryWeb3Wallet.web3Wallet);
    }
  }, [user]);

  // Updated effect to fetch user's balance
  useEffect(() => {
    const fetchUserBalance = async () => {
      try {
        const token = await getToken({ template: "optima" });
        const response = await fetch(
          "https://clownfish-app-x4ynf.ondigitalocean.app/users/credits",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setUserBalances({
          usdc: data.data.formatted.USDC || "0",
          baskets: data.data.formatted.baskets || {},
        });
      } catch (error) {
        console.error("Error fetching balance:", error);
      }
    };

    fetchUserBalance();
  }, [getToken]);

  // Add new useEffect to fetch the Optima wallet address
  useEffect(() => {
    const fetchOptimaWallet = async () => {
      try {
        const token = await getToken({ template: "optima" });
        const response = await fetch(
          "https://clownfish-app-x4ynf.ondigitalocean.app/users/account",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setOptimaWalletAddress(data.data.wallet); // adjust this based on actual API response structure
      } catch (error) {
        console.error("Error fetching Optima wallet:", error);
      }
    };

    fetchOptimaWallet();
  }, [getToken]);

  // Add loading timeout effect
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsBalanceLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  // Add effect to automatically switch network based on selected type
  useEffect(() => {
    if (selectedType === "BASKET") {
      setSelectedNetwork(NETWORKS.REDBELLY);
    } else if (selectedType === "USDT") {
      setSelectedNetwork(NETWORKS.BSC);
    }
  }, [selectedType]);

  // Helper function to get current balance based on selected type and basket
  const getCurrentBalance = () => {
    if (selectedType === "USDT") {
      return userBalances.usdc;
    } else if (selectedType === "BASKET" && selectedBasket) {
      return userBalances.baskets[selectedBasket] || "0";
    }
    return "0";
  };

  const handleConfirm = async (e) => {
    e.preventDefault();

    try {
      // Check KYC status first
      const token = await getToken({ template: "optima" });
      const response = await fetch(
        `https://clownfish-app-x4ynf.ondigitalocean.app/chains/151/kyc/${user.primaryWeb3Wallet.web3Wallet}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const kycData = await response.json();
      console.log("KYC verification status:", {
        isVerified: kycData.data.isVerified,
        message: kycData.data.message,
        fullResponse: kycData,
      });

      if (!kycData.data.isVerified) {
        setKycMessage(kycData.data.message);
        setKycRedirectUrl(kycData.data.redirectUrl);
        setShowKycModal(true);
        return;
      }

      // If KYC is verified, proceed with withdrawal
      const data = {
        type: "withdrawal",
        token_address:
          selectedType === "USDT" ? NETWORKS.BSC.usdtAddress : selectedBasket,
        chain_id: selectedNetwork.chainId,
        amount: parseFloat(amount),
        receiver_address: useCustomAddress
          ? customAddress
          : connectedWalletAddress,
      };

      setWithdrawData(data);
      setShowConfirmation(true);
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to process withdrawal. Please try again.");
    }
  };

  const handleClose = () => setShowConfirmation(false);

  const handleProceed = async () => {
    if (isProcessing) return;

    try {
      setIsLoading(true);
      setIsProcessing(true);

      const token = await getToken({ template: "optima" });

      // Prepare the request data based on withdrawal type
      const requestData = {
        amount: parseFloat(amount), // Send amount as is, without decimal conversion
        address_to_credit: withdrawData.receiver_address,
      };

      // Choose the appropriate endpoint based on withdrawal type
      const endpoint =
        selectedType === "BASKET"
          ? "https://clownfish-app-x4ynf.ondigitalocean.app/users/withdraw/baskets"
          : "https://clownfish-app-x4ynf.ondigitalocean.app/users/withdraw/USD";

      // Add basketId for basket withdrawals
      if (selectedType === "BASKET") {
        requestData.basketId = selectedBasket;
      }

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(
          errorData?.message ||
            `Withdrawal failed with status: ${response.status}`
        );
      }

      const data = await response.json();
      console.log("Withdrawal response:", data);
      setSuccessMessage("Withdrawal request submitted successfully!");
      setAmount("");
      setSelectedType("USDT");
      setSelectedBasket(null);
    } catch (error) {
      console.error("Withdrawal error:", error);
      alert(
        error.message ||
          "Withdrawal failed. Please wait a moment before trying again. If the problem persists, contact support."
      );
    } finally {
      setIsLoading(false);
      setIsProcessing(false);
      setShowConfirmation(false);
    }
  };

  const isValidEthereumAddress = (address) => {
    return /^0x[a-fA-F0-9]{40}$/.test(address);
  };

  const truncateAddress = (address) => {
    if (!address) return "No wallet connected";
    return `${address.slice(0, 6)}...${address.slice(-6)}`;
  };

  return (
    <div className="container-fluid p-4">
      <form onSubmit={handleConfirm}>
        <div className="row g-4">
          {/* Token Type Selection */}
          <div className="col-12">
            <label className="form-label text-muted mb-2">
              Select Token Type
            </label>
            <div className="input-group">
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary">
                  {selectedType}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setSelectedType("USDT")}>
                    USDT
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedType("BASKET")}>
                    BASKET
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          {/* Basket Selection (only shown when BASKET is selected) */}
          {selectedType === "BASKET" && (
            <div className="col-12">
              <label className="form-label text-muted mb-2">
                Select Basket
              </label>
              <select
                className="form-select"
                value={selectedBasket || ""}
                onChange={(e) => setSelectedBasket(e.target.value)}
                required
              >
                <option value="">Choose a basket...</option>
                <option value="1">Top 5 Crypto Basket</option>
                {/* Add more baskets as needed */}
              </select>
            </div>
          )}

          {/* Add Wallet Selection before the amount input */}
          <div className="col-12">
            <label className="form-label text-muted mb-2">
              Withdrawal Address
            </label>
            <div className="mb-3">
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  id="connectedWallet"
                  checked={!useCustomAddress}
                  onChange={() => setUseCustomAddress(false)}
                />
                <label className="form-check-label" htmlFor="connectedWallet">
                  Withdraw to Connected Wallet{" "}
                  <strong>{truncateAddress(connectedWalletAddress)}</strong>
                </label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  id="customWallet"
                  checked={useCustomAddress}
                  onChange={() => setUseCustomAddress(true)}
                />
                <label className="form-check-label" htmlFor="customWallet">
                  Withdraw to Custom Address
                </label>
              </div>
            </div>

            {useCustomAddress && (
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Ethereum address (0x...)"
                  value={customAddress}
                  onChange={(e) => setCustomAddress(e.target.value)}
                  required={useCustomAddress}
                  pattern="^0x[a-fA-F0-9]{40}$"
                />
              </div>
            )}
          </div>

          {/* Updated Amount Input with Balance */}
          <div className="col-12">
            <label className="form-label text-muted mb-2">Amount</label>
            <input
              type="number"
              className="form-control"
              placeholder="Enter amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
              min="0"
              step="any"
              max={getCurrentBalance()}
            />
            <div className="d-flex justify-content-between align-items-center mt-2">
              <small className="text-muted">
                Balance:{" "}
                {isBalanceLoading
                  ? "Loading..."
                  : `${parseFloat(getCurrentBalance()).toFixed(
                      2
                    )} ${selectedType}`}
              </small>
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm"
                onClick={() => setAmount(getCurrentBalance())}
                disabled={isBalanceLoading}
              >
                Max Withdraw
              </button>
            </div>
          </div>

          {/* Network Selection - Now correctly displays network name */}
          <div className="col-12">
            <label className="form-label text-muted mb-2">Network</label>
            <input
              type="text"
              className="form-control"
              value={selectedNetwork.name}
              disabled
            />
          </div>

          {/* Submit Button */}
          <div className="col-12">
            <button
              type="submit"
              className="btn btn-primary w-100 py-3 text-uppercase fw-bold"
              disabled={
                !amount ||
                parseFloat(amount) <= 0 ||
                (selectedType === "BASKET" && !selectedBasket) ||
                (useCustomAddress && !isValidEthereumAddress(customAddress))
              }
            >
              Confirm Withdrawal
            </button>
          </div>
        </div>
      </form>

      {/* KYC Verification Modal */}
      {showKycModal && (
        <div
          className="modal d-block"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">KYC Verification Required</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowKycModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <p>{kycMessage}</p>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowKycModal(false)}
                >
                  Close
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => (window.location.href = kycRedirectUrl)}
                >
                  Verify Now
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Update Confirmation Modal to show network info */}
      <ConfirmationPopup
        show={showConfirmation}
        onHide={handleClose}
        onConfirm={handleProceed}
        title="Confirm Withdrawal"
        body={
          isLoading ? (
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <p className="mt-2 text-black">Processing your withdrawal...</p>
            </div>
          ) : successMessage ? (
            <div className="text-center text-success">
              <i className="bi bi-check-circle-fill fs-1"></i>
              <p className="mt-2 text-black">{successMessage}</p>
            </div>
          ) : (
            <div className="text-black">
              <p>{`Are you sure you want to withdraw ${amount} ${selectedType}${
                selectedType === "BASKET" ? " tokens" : ""
              }?`}</p>
              <p className="small text-black">
                Network: {selectedNetwork.name}
              </p>
              <p className="small text-black">
                {useCustomAddress
                  ? `Withdrawing to address: ${customAddress}`
                  : "Withdrawing to your connected wallet"}
              </p>
            </div>
          )
        }
        confirmButtonDisabled={isProcessing || isLoading}
        confirmButtonText={isProcessing ? "Processing..." : "Confirm"}
        cancelButtonText={isProcessing ? "OK" : "Cancel"}
      />
    </div>
  );
}

export default WithdrawCrypto;
