import React, { useState, useEffect } from "react";
import { Nav, Tab } from "react-bootstrap";
import Qrcode from "../../assets/images/qr-code.png";
import { ethers } from "ethers";
import { useUser, useAuth } from "@clerk/clerk-react";
import axios from "axios";
import { Dropdown } from "react-bootstrap";

// Network configurations
const NETWORKS = {
  REDBELLY: {
    name: "RedBelly Mainnet",
    rpc: "https://governors.mainnet.redbelly.network",
    chainId: 151,
    explorer: "https://redbelly.routescan.io",
  },
  BSC: {
    name: "Binance Smart Chain",
    rpc: "https://bsc-dataseed.binance.org",
    usdtAddress: "0x55d398326f99059fF775485246999027B3197955", // BSC USDT contract
    chainId: 56,
    explorer: "https://bscscan.com",
  },
};

const Deposit = () => {
  const { user } = useUser();
  const { getToken } = useAuth();
  const [selectedCrypto, setSelectedCrypto] = useState("USDT");
  const [selectedNetwork, setSelectedNetwork] = useState(NETWORKS.BSC);
  const [walletAddress, setWalletAddress] = useState("");
  const [baskets, setBaskets] = useState([]);
  const [selectedBasketId, setSelectedBasketId] = useState(null);
  const [basketDetails, setBasketDetails] = useState(null);
  const [depositAmount, setDepositAmount] = useState("");
  const [optimaWalletAddress, setOptimaWalletAddress] = useState(null);
  const [depositFormAmount, setDepositFormAmount] = useState("");
  const [userBalance, setUserBalance] = useState("0");
  const [basketAddress, setBasketAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [txHash, setTxHash] = useState(null);
  const [basketBalance, setBasketBalance] = useState("0");
  const [isCreatingWallet, setIsCreatingWallet] = useState(false);
  const [showWalletCreatedModal, setShowWalletCreatedModal] = useState(false);
  const [isBalanceLoading, setIsBalanceLoading] = useState(true);
  const [showKycModal, setShowKycModal] = useState(false);
  const [kycMessage, setKycMessage] = useState("");
  const [kycRedirectUrl, setKycRedirectUrl] = useState("");

  React.useEffect(() => {
    if (user?.primaryWeb3Wallet) {
      setWalletAddress(user.primaryWeb3Wallet.web3Wallet);
    }
  }, [user]);

  {
    /* Fetching baskets */
  }
  useEffect(() => {
    const fetchBaskets = async () => {
      try {
        const token = await getToken({ template: "optima" });

        const response = await axios.get(
          "https://clownfish-app-x4ynf.ondigitalocean.app/baskets/1/prices/historical",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Baskets response:", response.data);
        setBaskets(response.data);
      } catch (error) {
        console.error("Error fetching baskets:", error);
      }
    };
    fetchBaskets();
  }, [getToken]);

  {
    /* Fetching optima wallet address */
  }
  useEffect(() => {
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const fetchOptimaWallet = async (retryCount = 0) => {
      try {
        const token = await getToken({ template: "optima" });

        // Add delay before making the request
        await delay(1000); // 1 second delay

        const response = await axios.get(
          "https://clownfish-app-x4ynf.ondigitalocean.app/users/account",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(response, "KVO IDVA");

        if (response.data?.data?.wallet === null) {
          setIsCreatingWallet(true);

          // Add delay before creating wallet
          await delay(1000); // 1 second delay

          const userData = {
            name: user.firstName || "",
            surname: user.lastName || "",
            email: user.primaryEmailAddress?.emailAddress || "",
            country: user.publicMetadata?.country || "Unknown",
          };

          const createResponse = await axios.post(
            "https://clownfish-app-x4ynf.ondigitalocean.app/users/create",
            userData,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          setOptimaWalletAddress(createResponse.data?.data?.wallet);
          setShowWalletCreatedModal(true);
          setIsCreatingWallet(false);
        } else {
          const walletAddress = response.data?.data?.wallet;
          if (!walletAddress) {
            throw new Error("Could not find wallet address in API response");
          }
          setOptimaWalletAddress(walletAddress);
        }
      } catch (error) {
        console.error("Detailed error in fetchOptimaWallet:", error);

        // Handle rate limit error with retries
        if (error.response?.status === 429 && retryCount < 3) {
          console.log(
            `Rate limited, retrying in ${(retryCount + 1) * 2} seconds...`
          );
          await delay((retryCount + 1) * 2000); // Exponential backoff
          return fetchOptimaWallet(retryCount + 1);
        }

        alert(`Failed to fetch/create wallet: ${error.message}`);
        setIsCreatingWallet(false);
      }
    };

    fetchOptimaWallet();
  }, [getToken, user]);

  // Add effect to automatically switch network based on selected crypto
  useEffect(() => {
    if (selectedCrypto === "BASKET") {
      setSelectedNetwork(NETWORKS.REDBELLY);
    } else if (selectedCrypto === "USDT") {
      setSelectedNetwork(NETWORKS.BSC);
    }
  }, [selectedCrypto]);

  // Update balance fetching useEffect
  useEffect(() => {
    const fetchBalance = async () => {
      setIsBalanceLoading(true);
      try {
        if (!walletAddress || !selectedNetwork) {
          setUserBalance("0");
          setBasketBalance("0");
          return;
        }

        const provider = new ethers.providers.JsonRpcProvider(
          selectedNetwork.rpc
        );

        if (selectedCrypto === "BASKET" && basketAddress) {
          // Reset USDT balance when viewing Basket
          setUserBalance("0");

          // Fetch basket token balance on RedBelly
          const basketContract = new ethers.Contract(
            basketAddress,
            [
              "function balanceOf(address account) external view returns (uint256)",
            ],
            provider
          );

          try {
            const balance = await basketContract.balanceOf(walletAddress);
            setBasketBalance(ethers.utils.formatUnits(balance, 18));
          } catch (error) {
            console.error("Error fetching basket balance:", error);
            setBasketBalance("0");
          }
        } else if (selectedCrypto === "USDT") {
          // Reset basket balance when viewing USDT
          setBasketBalance("0");

          // Fetch USDT balance on BSC
          const usdtContract = new ethers.Contract(
            NETWORKS.BSC.usdtAddress,
            [
              "function balanceOf(address account) external view returns (uint256)",
            ],
            provider
          );

          try {
            const balance = await usdtContract.balanceOf(walletAddress);
            setUserBalance(ethers.utils.formatUnits(balance, 18));
          } catch (error) {
            console.error("Error fetching USDT balance:", error);
            setUserBalance("0");
          }
        }
      } catch (error) {
        console.error("Error in balance fetching:", error);
        setUserBalance("0");
        setBasketBalance("0");
      } finally {
        setIsBalanceLoading(false);
      }
    };

    fetchBalance();
  }, [walletAddress, selectedNetwork, selectedCrypto, basketAddress]);

  // Add effect to fetch basket address
  useEffect(() => {
    const fetchBasketDetails = async () => {
      if (!selectedBasketId) return;

      try {
        const token = await getToken({ template: "optima" });
        console.log("Fetching basket with ID:", selectedBasketId);

        const response = await axios.get(
          `https://clownfish-app-x4ynf.ondigitalocean.app/baskets/${selectedBasketId}/overview`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("Basket details response:", response.data);
        // Get the address from the nested data structure
        const rawBasketAddress = response.data.data.addresses["151"];

        if (!rawBasketAddress) {
          throw new Error("Basket address not found in response");
        }

        // Use ethers to format the address correctly
        const formattedBasketAddress =
          ethers.utils.getAddress(rawBasketAddress);
        console.log("Formatted basket address:", formattedBasketAddress);

        setBasketAddress(formattedBasketAddress);
        setBasketDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching basket details:", error);
        alert(`Failed to fetch basket details: ${error.message}`);
      }
    };

    fetchBasketDetails();
  }, [selectedBasketId, getToken]);

  // Update the handleExchangeForCredits function
  const handleExchangeForCredits = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setTxHash(null);

    try {
      // Check KYC status first
      const token = await getToken({ template: "optima" });
      const response = await fetch(
        `https://clownfish-app-x4ynf.ondigitalocean.app/chains/151/kyc/${user.primaryWeb3Wallet.web3Wallet}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const kycData = await response.json();
      console.log("KYC verification status:", {
        isVerified: kycData.data.isVerified,
        message: kycData.data.message,
        fullResponse: kycData,
      });

      if (!kycData.data.isVerified) {
        setKycMessage(kycData.data.message);
        setKycRedirectUrl(kycData.data.redirectUrl);
        setShowKycModal(true);
        setIsLoading(false);
        return;
      }

      // Continue with USDT transfer if KYC is verified
      if (!optimaWalletAddress) {
        alert(
          "Optima wallet address not available. Please wait or refresh the page."
        );
        return;
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      // Check and switch network if needed
      const network = await provider.getNetwork();
      if (network.chainId !== selectedNetwork.chainId) {
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [
              { chainId: ethers.utils.hexValue(selectedNetwork.chainId) },
            ],
          });
        } catch (switchError) {
          throw new Error(`Please switch to ${selectedNetwork.name} network`);
        }
      }

      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();

      // Create USDT contract instance
      const usdtContract = new ethers.Contract(
        NETWORKS.BSC.usdtAddress,
        [
          "function transfer(address to, uint256 amount) external returns (bool)",
        ],
        signer
      );

      // Convert amount to wei (USDT has 18 decimals)
      const amountWei = ethers.utils.parseUnits(depositFormAmount, 18);

      // Send USDT transfer transaction
      const tx = await usdtContract.transfer(optimaWalletAddress, amountWei, {
        gasLimit: 100000, // Adjust gas limit as needed
      });

      console.log("Transaction sent:", tx.hash);
      setTxHash(tx.hash);

      const receipt = await tx.wait();
      console.log("Transaction receipt:", receipt);

      if (receipt.status !== 1) {
        throw new Error("Transaction failed");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to process transaction. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Update the handleBasketDeposit function
  const handleBasketDeposit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setTxHash(null);

    try {
      // Check KYC status first
      const token = await getToken({ template: "optima" });
      const response = await fetch(
        `https://clownfish-app-x4ynf.ondigitalocean.app/chains/151/kyc/${user.primaryWeb3Wallet.web3Wallet}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const kycData = await response.json();
      console.log("KYC verification status:", {
        isVerified: kycData.data.isVerified,
        message: kycData.data.message,
        fullResponse: kycData,
      });

      if (!kycData.data.isVerified) {
        setKycMessage(kycData.data.message);
        setKycRedirectUrl(kycData.data.redirectUrl);
        setShowKycModal(true);
        setIsLoading(false);
        return;
      }

      // Continue with basket deposit if KYC is verified
      if (!basketAddress) {
        throw new Error("Basket address not available");
      }

      console.log("Using basket address:", basketAddress);

      let provider = new ethers.providers.Web3Provider(window.ethereum);

      // Check and switch to RedBelly network if needed
      const network = await provider.getNetwork();
      if (network.chainId !== NETWORKS.REDBELLY.chainId) {
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [
              { chainId: ethers.utils.hexValue(NETWORKS.REDBELLY.chainId) },
            ],
          });
        } catch (switchError) {
          // Handle the error if the chain has not been added to MetaMask
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: ethers.utils.hexValue(NETWORKS.REDBELLY.chainId),
                    chainName: NETWORKS.REDBELLY.name,
                    rpcUrls: [NETWORKS.REDBELLY.rpc],
                  },
                ],
              });
            } catch (addError) {
              throw new Error(
                `Failed to add RedBelly network: ${addError.message}`
              );
            }
          } else {
            throw new Error(
              `Please switch to ${NETWORKS.REDBELLY.name} network`
            );
          }
        }

        // Reinitialize provider after network switch
        provider = new ethers.providers.Web3Provider(window.ethereum);
      }

      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();

      // Verify we're on the correct network after getting signer
      const currentNetwork = await provider.getNetwork();
      if (currentNetwork.chainId !== NETWORKS.REDBELLY.chainId) {
        throw new Error("Network switch was not successful. Please try again.");
      }

      const userAddress = await signer.getAddress();
      console.log("Connected wallet:", userAddress);

      const basketContract = new ethers.Contract(
        basketAddress,
        [
          "function exchangeForCredits(uint256 amount, address smartWallet) external",
        ],
        signer
      );

      const depositAmountWei = ethers.utils.parseUnits(depositFormAmount, 18);
      console.log("Deposit amount in wei:", depositAmountWei.toString());

      console.log("Executing exchangeForCredits...");
      const tx = await basketContract.exchangeForCredits(
        depositAmountWei,
        optimaWalletAddress,
        {
          gasLimit: 300000,
        }
      );
      console.log("Transaction hash:", tx.hash);
      setTxHash(tx.hash);

      const receipt = await tx.wait();
      console.log("Transaction receipt:", receipt);

      if (receipt.status !== 1) {
        throw new Error("Transaction failed");
      }
    } catch (error) {
      console.error("Transaction error:", error);
      alert(`Transaction failed: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container-fluid p-4">
      <div className="row g-4">
        {/* Token Type Selection */}
        <div className="col-12">
          <label className="form-label text-muted mb-2">
            Select Token Type
          </label>
          <div className="input-group">
            <Dropdown>
              <Dropdown.Toggle variant="outline-secondary">
                {selectedCrypto}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setSelectedCrypto("USDT")}>
                  USDT
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setSelectedCrypto("BASKET")}>
                  BASKET
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        {selectedCrypto === "USDT" ? (
          <>
            {/* Network Selection for USDT */}
            <div className="col-12">
              <label className="form-label text-muted mb-2">Network</label>
              <input
                type="text"
                className="form-control"
                value={selectedNetwork.name}
                disabled
              />
            </div>

            {/* Deposit Address Display */}
            <div className="col-12">
              <label className="form-label text-muted mb-2">
                Deposit Address
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={optimaWalletAddress || "Loading..."}
                  readOnly
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={() => {
                    if (optimaWalletAddress) {
                      navigator.clipboard.writeText(optimaWalletAddress);
                      alert("Address copied to clipboard!");
                    }
                  }}
                >
                  Copy
                </button>
              </div>
              <small className="text-muted d-block mt-2">
                Send your USDT to this address.
              </small>
            </div>

            {/* Important Notice */}
            <div className="col-12">
              <div className="alert alert-warning" role="alert">
                <h6 className="alert-heading">Important:</h6>
                <ul className="mb-0">
                  <li>Only send USDT on {selectedNetwork.name}</li>
                  <li>Minimum deposit: 1 USDT</li>
                  <li>Deposits will be credited after network confirmation</li>
                  <li>Sending other tokens may result in permanent loss</li>
                  <li>
                    USDT Contract address ending digits:{" "}
                    {NETWORKS.BSC.usdtAddress.slice(-6)}
                  </li>
                </ul>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* Basket Selection (only shown when BASKET is selected) */}
            <div className="col-12">
              <label className="form-label text-muted mb-2">
                Select Basket
              </label>
              <select
                className="form-select"
                value={selectedBasketId || ""}
                onChange={(e) => setSelectedBasketId(e.target.value)}
                required
              >
                <option value="">Choose a basket...</option>
                <option value="1">Top 5 Crypto Basket</option>
              </select>
            </div>

            {/* Amount Input with Balance Display */}
            <div className="col-12">
              <label className="form-label text-muted mb-2">Amount</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter amount"
                value={depositFormAmount}
                onChange={(e) => setDepositFormAmount(e.target.value)}
                required
                min="0"
                step="any"
                max={selectedCrypto === "BASKET" ? basketBalance : userBalance}
              />
              <div className="d-flex justify-content-between align-items-center mt-2">
                <small className="text-muted">
                  Balance:{" "}
                  {isBalanceLoading
                    ? "Loading..."
                    : selectedCrypto === "BASKET"
                    ? `${parseFloat(basketBalance).toFixed(2)} ${
                        basketDetails?.symbol || "BASKET"
                      }`
                    : `${parseFloat(userBalance).toFixed(2)} USDT`}
                </small>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  onClick={() =>
                    setDepositFormAmount(
                      selectedCrypto === "BASKET" ? basketBalance : userBalance
                    )
                  }
                  disabled={isBalanceLoading}
                >
                  Max Deposit
                </button>
              </div>
            </div>

            {/* Submit Button */}
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary w-100 py-3 text-uppercase fw-bold"
                disabled={
                  isLoading ||
                  !depositFormAmount ||
                  parseFloat(depositFormAmount) <= 0
                }
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Processing...
                  </>
                ) : (
                  "Confirm Deposit"
                )}
              </button>
            </div>
          </>
        )}
      </div>

      {/* Loading Modal */}
      {isLoading && (
        <div
          className="modal d-block"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center p-4">
                <div className="spinner-border text-primary mb-3" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <h5>Processing Transaction...</h5>
                {txHash && (
                  <div className="mt-3">
                    <small className="text-muted">
                      Transaction Hash:{" "}
                      <a
                        href={`${selectedNetwork.explorer}/tx/${txHash}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View on Explorer
                      </a>
                    </small>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Success Modal */}
      {!isLoading && txHash && (
        <div
          className="modal d-block"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center p-4">
                <div className="text-success mb-3">
                  <i
                    className="bi bi-check-circle"
                    style={{ fontSize: "3rem" }}
                  ></i>
                </div>
                <h5>Transaction Successful!</h5>
                <div className="mt-3">
                  <a
                    href={`${selectedNetwork.explorer}/tx/${txHash}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-outline-primary"
                  >
                    View on Explorer
                  </a>
                  <button
                    className="btn btn-secondary ms-2"
                    onClick={() => setTxHash(null)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Wallet Creation Modal */}
      {isCreatingWallet && (
        <div
          className="modal d-block"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center p-4">
                <div className="spinner-border text-primary mb-3" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <h5>Creating Optima Wallet...</h5>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Wallet Created Success Modal */}
      {showWalletCreatedModal && (
        <div
          className="modal d-block"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center p-4">
                <div className="text-success mb-3">
                  <i
                    className="bi bi-check-circle"
                    style={{ fontSize: "3rem" }}
                  ></i>
                </div>
                <h5>Successfully Created Optima Wallet!</h5>
                <button
                  className="btn btn-primary mt-3"
                  onClick={() => setShowWalletCreatedModal(false)}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* KYC Verification Modal */}
      {showKycModal && (
        <div
          className="modal d-block"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">KYC Verification Required</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowKycModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <p>{kycMessage}</p>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowKycModal(false)}
                >
                  Close
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => (window.location.href = kycRedirectUrl)}
                >
                  Verify Now
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Deposit;
