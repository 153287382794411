import React, { useEffect, useRef, useState } from "react";
import { createChart } from "lightweight-charts";

function Chart({ basketName, marketData, historicalPrices, basketDetails }) {
  const chartContainerRef = useRef();
  const chartRef = useRef();
  const seriesRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTimeframe, setSelectedTimeframe] = useState("7d");

  const handleTimeframeChange = (timeframe) => {
    setSelectedTimeframe(timeframe);
    if (!chartRef.current || !seriesRef.current) return;

    const now = new Date();
    let fromTime;

    switch (timeframe) {
      case "1h":
        fromTime = new Date(now.getTime() - 1 * 60 * 60 * 1000);
        break;
      case "12h":
        fromTime = new Date(now.getTime() - 12 * 60 * 60 * 1000);
        break;
      case "1d":
        fromTime = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        break;
      case "7d":
      default:
        fromTime = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    }

    // Get all data points and find visible range
    const allData = seriesRef.current.data();

    // Convert fromTime to seconds for comparison
    const fromTimeSeconds = Math.floor(fromTime.getTime() / 1000);
    const nowSeconds = Math.floor(now.getTime() / 1000);

    const visibleData = allData.filter((point) => {
      // Ensure we're comparing timestamps in the same format
      const pointTime =
        typeof point.time === "number"
          ? point.time
          : new Date(point.time).getTime() / 1000;
      return pointTime >= fromTimeSeconds && pointTime <= nowSeconds;
    });

    if (visibleData.length > 1) {
      const firstPoint = visibleData[0];
      const lastPoint = visibleData[visibleData.length - 1];

      // Determine color based on price movement
      const isUptrend = lastPoint.value > firstPoint.value;
      const color = isUptrend ? "#26a69a" : "#ef5350";

      // Update the line color
      seriesRef.current.applyOptions({
        color: color,
        lineWidth: 2,
      });
    }

    // Set visible range
    chartRef.current.timeScale().setVisibleRange({
      from: fromTimeSeconds,
      to: nowSeconds,
    });
  };

  const styles = {
    buttonGroup: {
      display: "flex",
      gap: "2px",
    },
    button: {
      padding: "4px 8px",
      fontSize: "12px",
      border: "1px solid #e0e3eb",
      background: "transparent",
      color: "#787b86",
      cursor: "pointer",
      borderRadius: "4px",
    },
    activeButton: {
      background: "#f0f3fa",
      color: "#2962ff",
      border: "1px solid #e0e3eb",
    },
    header: {
      display: "flex",
      padding: "8px",
      borderBottom: "1px solid #e0e3eb",
      alignItems: "center",
    },
    priceInfo: {
      marginLeft: "12px",
    },
    symbol: {
      color: "#787b86",
      fontWeight: "bold",
    },
    price: {
      marginLeft: "8px",
      color: "#26a69a",
    },
    card: {
      borderRadius: "8px",
      border: "1px solid #e0e3eb",
      backgroundColor: "#ffffff",
      width: "100%",
      height: "100%",
      padding: "10px",
      marginBottom: "15px",
    },
    chartWrapper: {
      width: "100%",
      height: "470px",
      position: "relative",
      marginTop: "5px",
      overflow: "visible",
    },
    chartContainer: {
      width: "100%",
      height: "100%",
      "& #tv-attr-logo": {
        display: "none !important",
      },
    },
    title: {
      fontSize: "20px",
      fontWeight: "bold",
      marginBottom: "20px",
      color: "#333",
    },
    container: {
      display: "flex",
      width: "100%",
    },
    textSection: {
      width: "20%",
      padding: "20px",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      border: "1px solid #e0e3eb",
      marginTop: "40px",
    },
    textTitle: {
      fontSize: "16px",
      fontWeight: "bold",
      marginBottom: "15px",
      color: "#333",
    },
    textContent: {
      fontSize: "14px",
      lineHeight: "1.6",
      color: "#787b86",
    },
    statsGrid: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "30px",
      marginTop: "40px",
    },
    statLabel: {
      color: "#787b86",
      fontSize: "14px",
    },
    statValue: {
      color: "#333",
      fontSize: "14px",
      fontWeight: "500",
    },
    marketStatus: {
      color: "#26a69a",
      display: "flex",
      alignItems: "center",
      gap: "6px",
      fontSize: "14px",
      marginTop: "10px",
    },
    priceChange: {
      color: "#26a69a",
      marginLeft: "8px",
    },
    rangeInfo: {
      marginTop: "40px",
    },
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.remove();
      chartRef.current = null;
    }

    const fetchData = async () => {
      try {
        console.log("Fetching data started");
        const chart = createChart(chartContainerRef.current, {
          layout: {
            background: { color: "#ffffff" },
            textColor: "#333",
          },
          grid: {
            vertLines: { color: "#f0f0f0" },
            horzLines: { color: "#f0f0f0" },
          },
          width: chartContainerRef.current.clientWidth,
          height: 400,
          timeScale: {
            timeVisible: true,
            secondsVisible: false,
            rightOffset: 5,
          },
          watermark: {
            visible: false,
          },
          disableLogo: true,
        });

        chartRef.current = chart;

        const lineSeries = chart.addLineSeries({
          color: "#26a69a",
          lineWidth: 2,
          lastValueVisible: false,
          priceLineVisible: false,
        });
        seriesRef.current = lineSeries;

        // Set the data
        if (historicalPrices && historicalPrices.length > 0) {
          lineSeries.setData(historicalPrices);

          // Set initial 7-day view and color
          const now = new Date();
          const sevenDaysAgo = new Date(
            now.getTime() - 7 * 24 * 60 * 60 * 1000
          );

          const visibleData = historicalPrices.filter(
            (d) => d.time >= sevenDaysAgo.getTime() / 1000
          );

          if (visibleData.length > 1) {
            const firstPoint = visibleData[0];
            const lastPoint = visibleData[visibleData.length - 1];
            const color =
              lastPoint.value > firstPoint.value ? "#26a69a" : "#ef5350";

            lineSeries.applyOptions({
              color: color,
              lineWidth: 2,
            });
          }

          // Set initial 7-day timeframe with some padding
          const lastTimestamp =
            historicalPrices[historicalPrices.length - 1].time;
          chart.timeScale().setVisibleRange({
            from: sevenDaysAgo.getTime() / 1000,
            to: lastTimestamp + 3600,
          });
        }

        setIsLoading(false);

        const handleResize = () => {
          chart.applyOptions({
            width: chartContainerRef.current.clientWidth,
          });
        };

        window.addEventListener("resize", handleResize);

        return () => {
          window.removeEventListener("resize", handleResize);
          chart.remove();
        };
      } catch (error) {
        console.error("Chart error:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [historicalPrices]);

  if (isLoading) {
    return (
      <div style={styles.card}>
        <h2 style={styles.title}>{`${
          basketDetails?.data?.symbol || "Loading..."
        } / USD`}</h2>
        <div
          style={{
            ...styles.container,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Loading chart data...
        </div>
      </div>
    );
  }

  return (
    <div style={styles.card}>
      <style>
        {`
          #tv-attr-logo {
            display: none !important;
          }
        `}
      </style>
      <div style={styles.container}>
        <div style={styles.chartWrapper}>
          <div style={styles.header}>
            <div style={styles.buttonGroup}>
              {["1h", "12h", "1d", "7d"].map((timeframe) => (
                <button
                  key={timeframe}
                  style={{
                    ...styles.button,
                    ...(selectedTimeframe === timeframe
                      ? styles.activeButton
                      : {}),
                  }}
                  onClick={() => handleTimeframeChange(timeframe)}
                >
                  {timeframe}
                </button>
              ))}
            </div>
            <div style={styles.priceInfo}>
              <span style={styles.symbol}>{`${
                basketDetails?.data?.symbol || "Loading..."
              } / USD`}</span>
            </div>
          </div>
          <div ref={chartContainerRef} style={styles.chartContainer} />
        </div>
      </div>
    </div>
  );
}

export default Chart;
