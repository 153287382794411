import React, { useEffect, useState, useRef } from "react";
import "./Banner.css";

const Banner = ({ data }) => {
  // Ensure data has a default value
  const items = data || [];
  const [itemCount, setItemCount] = useState(items.length);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      document.documentElement.style.setProperty("--item-count", items.length);
    }
  }, [data, items]);

  const getChangeClass = (changeValue) => {
    // Convert to number and check if it's negative
    return Number(changeValue) < 0 ? "negative" : "positive";
  };

  return (
    <div className="banner-wrapper">
      <div
        ref={contentRef}
        className="banner-content"
        style={{
          animation: `scroll ${Math.max(items.length * 2, 1)}s linear infinite`,
        }}
      >
        {/* Render items twice to create seamless loop */}
        {[...items, ...items].map((item, index) => (
          <div className="banner-tile" key={`${index}-${item.basket_symbol}`}>
            <div className="tile-name">
              {item.basket_symbol === "Smart Contract Platform ex ETH"
                ? "SCPE"
                : item.basket_symbol}
            </div>
            <div className="tile-amount">
              ${Number(item.current_value).toFixed(2)}
            </div>
            <div
              className={`tile-change ${getChangeClass(item.return_1_day_pct)}`}
            >
              {Number(item.return_1_day_pct).toFixed(2)}%
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Banner;
