import React, { useState, useEffect } from "react";
import heroImage from "../../../assets/images/landingpage/main-image.png";
import heroShape from "../../../assets/images/landingpage/hero-shape.svg";
import Banner from "../../../Component/Banner/Banner";
import axios from "axios";

// Add styles for mobile devices
const mobileStyles = `
  body {
    overflow-x: hidden;
  }

  .hero-area {
    overflow: hidden;
    width: 100%;
    position: relative;
  }

  .hero-shape {
    position: absolute;
    width: 100%;
    height: auto;
    z-index: 0;
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .hero-image img {
    width: 580px;
    height: 450px;
    object-fit: cover;
    margin-left: 28px;
    border-radius: 20px;
    margin-top: 0px;
  }

  .mobile-only-image {
    display: none;
  }

  .strange {
    display: none;
  }

  @media screen and (max-width: 768px) {
    
    .hero-shape {
      max-width: 100vw;
    }
    
    .desktop-only-image {
      display: none;
    }
    
    .mobile-only-image {
      display: block;
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
    }
    
    .mobile-only-image img {
      width: 100%;
      max-width: 300px;
      height: auto;
      margin: 0 auto;
      display: block;
      border-radius: 12px;
    }

    .strange {
      display: block;
      width: 100%;
      max-width: 250px;
      height: auto;
      margin: 10px auto;
      border-radius: 40px;
      transition: transform 0.3s ease;
      object-fit: cover;
      position: relative;
      top: -20px;
    }

    .strange:hover {
      transform: translateY(-5px);
      box-shadow: 0 12px 32px rgba(0, 0, 0, 0.18);
    }

    /* Content adjustments */
    .hero-content {
      padding: 0;
    }
    .hero-content h1 {
      font-size: 24px !important;
      margin-bottom: 15px;
    }
    .hero-content p {
      font-size: 16px !important;
      line-height: 1.5;
    }
  }
`;

const HeroArea2 = () => {
  const [baskets, setBasketsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBasketData = async () => {
      setLoading(true);
      try {
        const basketIds = Array.from({ length: 9 }, (_, i) => i + 1);

        const basketsData = await Promise.all(
          basketIds.map(async (id) => {
            const [overviewResponse, hourlyPricesResponse] = await Promise.all([
              axios.get(
                `https://clownfish-app-x4ynf.ondigitalocean.app/baskets/${id}/overview`
              ),
              axios.get(
                `https://clownfish-app-x4ynf.ondigitalocean.app/baskets/${id}/prices/hourly`
              ),
            ]);

            const overview = overviewResponse.data.data;
            const hourlyPrices = hourlyPricesResponse.data.data.hourlyPrices;

            console.log("overview", overview);
            console.log("hourlyPrices", hourlyPrices);

            // Get the last item from the hourly prices array
            const latestPrice = hourlyPrices[hourlyPrices.length - 1];

            return {
              basket_symbol: overview?.symbol || "N/A",
              current_value: latestPrice?.price || 0,
              return_1_day_pct: latestPrice?.returns
                ? parseFloat(latestPrice.returns.toFixed(2))
                : 0,
            };
          })
        );

        // Filter out any invalid data
        const validBasketsData = basketsData.filter(
          (basket) =>
            basket.basket_symbol !== "N/A" &&
            !isNaN(basket.current_value) &&
            !isNaN(basket.return_1_day_pct)
        );

        console.log("Data being sent to Banner:", validBasketsData);

        setBasketsData(validBasketsData);
      } catch (err) {
        setError(err);
        console.error("Error fetching basket data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchBasketData();
  }, []);

  return (
    <>
      <style>{mobileStyles}</style>
      <section
        className="hero-area light-blue"
        id="heroarea"
        style={{
          position: "relative",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <img className="hero-shape" src={heroShape} alt="#" />
        <div className="container">
          <div className="row align-items-center">
            <img src={heroImage} alt="#" className="strange" />
            <div className="col-lg-6 col-md-12 col-12 order-lg-1">
              <div className="hero-content" style={{ textAlign: "center" }}>
                <h1 className="wow fadeInUp" data-wow-delay=".4s">
                  The Golden Standard for Tokenized Investment Strategies
                </h1>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  <b>
                    <i>Diversify your portfolio with Optima baskets!</i>
                  </b>
                  <br />
                  <br />
                  Optima strategy baskets are chain agnostic, regulatory
                  complaint, fully backed by their underlying assets, freely
                  tradeable and integrated with DeFi protocols.
                </p>
                <div
                  className="tradingview-widget-container"
                  style={{ borderRadius: "10px", overflow: "hidden" }}
                >
                  <Banner data={baskets} />
                  {/* <iframe
                    title="TradingView Widget"
                    src="https://s.tradingview.com/embed-widget/ticker-tape/?locale=en#%7B%22symbols%22%3A%5B%7B%22description%22%3A%22Doge%22%2C%22proName%22%3A%22HUOBI%3ADOGUSDT%22%7D%2C%7B%22description%22%3A%22Ada%22%2C%22proName%22%3A%22BINANCE%3AADAUSDT%22%7D%2C%7B%22description%22%3A%22Solana%22%2C%22proName%22%3A%22BINANCE%3ASOLUSDT%22%7D%2C%7B%22description%22%3A%22Shiba%22%2C%22proName%22%3A%22BINANCE%3ASHIBUSDT%22%7D%2C%7B%22description%22%3A%22Bnb%22%2C%22proName%22%3A%22BINANCE%3ABNBUSDT%22%7D%2C%7B%22description%22%3A%22Eth%22%2C%22proName%22%3A%22BITSTAMP%3AETHUSD%22%7D%2C%7B%22description%22%3A%22Btc%22%2C%22proName%22%3A%22BINANCE%3ABTCUSDT%22%7D%2C%7B%22description%22%3A%22Dot%22%2C%22proName%22%3A%22BINANCE%3ADOTUSDT%22%7D%5D%2C%22showSymbolLogo%22%3Afalse%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22displayMode%22%3A%22adaptive%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A46%2C%22utm_source%22%3A%22www.example.com%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22ticker-tape%22%7D"
                    style={{
                      boxSizing: "border-box",
                      height: "74px",
                      width: "100%",
                    }}
                  /> */}
                </div>
                <div
                  className="button wow fadeInUp"
                  data-wow-delay=".8s"
                  style={{ textAlign: "left" }}
                >
                  <a
                    onClick={() => {
                      document
                        .getElementById("contact")
                        .scrollIntoView({ behavior: "smooth" });
                    }}
                    className="btn bg-light text-primary"
                    style={{ cursor: "pointer" }}
                  >
                    Partner With Us
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 mobile-order-first order-lg-2">
              <div className="hero-image">
                <img
                  src={heroImage}
                  alt="hero image"
                  className="desktop-only-image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroArea2;
