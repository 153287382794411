import React, { useState } from "react";
import { useAuth } from "@clerk/clerk-react";

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    queryType: "Product question",
    message: "",
  });

  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    error: null,
    success: false,
  });

  const { getToken } = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus({ loading: true, error: null, success: false });

    try {
      const token = await getToken({ template: "optima" });

      const response = await fetch(
        "https://clownfish-app-x4ynf.ondigitalocean.app/forms",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            reason: formData.queryType,
            message: formData.message,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to submit form");
      }

      setSubmitStatus({ loading: false, error: null, success: true });
      setFormData({
        name: "",
        email: "",
        queryType: "Product question",
        message: "",
      });
    } catch (error) {
      setSubmitStatus({ loading: false, error: error.message, success: false });
    }
  };

  return (
    <section className="contactus section light-blue" id="contact">
      <div className="container">
        <h2
          className="wow fadeInUp"
          data-wow-delay=".4s"
          style={{ textAlign: "center", marginBottom: "5%" }}
        >
          We'd Love to Hear From You
        </h2>
        <div className="row">
          {/* Left Column - Description */}
          <div className="col-lg-6">
            <div style={{ width: "90%", margin: "0 auto" }}>
              <p
                className="wow fadeInUp"
                data-wow-delay=".6s"
                style={{ color: "#002041" }}
              >
                Let's work together to advance adoption and development in this
                exciting field. Reach out to us:
                <br />
                <hr style={{ margin: "15px 0", border: "1px solid #e0e0e0" }} />
                <b>- Users</b> interested in learning more about Optima and
                getting started.
                <br />
                <br />
                <b>- Protocol Developers</b> or supporters of L1 or L2
                blockchains who want to integrate Optima Basket Tokens into your
                ecosystem.
                <br />
                <br />
                <b>- Fintech, Crypto Exchanges, or CeFi Providers</b> looking to
                offer Optima Basket Tokens to your clients.
                <br />
                <br />
                <b>- Broker-Dealers</b> interested in providing Optima
                strategies to your clients.
                <br />
              </p>
            </div>
          </div>

          {/* Right Column - Form */}
          <div className="col-lg-6">
            <form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInputname"
                  placeholder="Your Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                <label htmlFor="floatingInputname">Your Name</label>
              </div>
              <div className="form-floating">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Your Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <label>Your Email</label>
              </div>
              <div className="form-floating">
                <select
                  className="form-select"
                  id="floatingSelect"
                  name="queryType"
                  value={formData.queryType}
                  onChange={handleChange}
                >
                  <option value="Product question">
                    I have questions about any of your products
                  </option>
                  <option value="Partnership opportunity">
                    I would like to discuss a partnership opportunity
                  </option>
                  <option value="Other question">
                    I have another type of request
                  </option>
                </select>
                <label>Reason</label>
              </div>
              <div className="form-floating">
                <textarea
                  className="form-control"
                  placeholder="Message (Optional)"
                  style={{ height: "160px" }}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                <label>Message (Optional)</label>
              </div>
              {submitStatus.error && (
                <div
                  className="alert alert-danger d-flex align-items-center"
                  role="alert"
                >
                  <i className="bi bi-exclamation-triangle-fill me-2"></i>
                  <div>Failed to send message: {submitStatus.error}</div>
                </div>
              )}
              {submitStatus.success && (
                <div
                  className="alert alert-success d-flex align-items-center"
                  role="alert"
                >
                  <i className="bi bi-check-circle-fill me-2"></i>
                  <div>
                    Message sent successfully! We'll get back to you soon.
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>

        {/* Moved button outside the row, directly under container */}
        <div className="d-flex justify-content-center mt-4">
          <button
            type="submit"
            className="btn btn-primary wow fadeInUp"
            data-wow-delay=".8s"
            disabled={submitStatus.loading}
            style={{
              padding: "12px 30px",
              fontSize: "16px",
              fontWeight: "600",
              borderRadius: "30px",
              background: "#002041",
              border: "none",
              boxShadow: "0 4px 6px rgba(45, 92, 254, 0.2)",
              transition: "all 0.3s ease",
            }}
            onMouseOver={(e) => (e.target.style.transform = "translateY(-2px)")}
            onMouseOut={(e) => (e.target.style.transform = "translateY(0)")}
            onClick={handleSubmit}
          >
            {submitStatus.loading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                Sending...
              </>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
