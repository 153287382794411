import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import ConfirmationPopup from "../Ui Component/ConfirmationPopup";
import { useAuth } from "@clerk/clerk-react";

function Spot({
  balances,
  marketData,
  selectedBasketId,
  basketDetails,
  isLoading,
  onTradeResponse,
  checkKycStatus,
  isTradeable,
}) {
  const { getToken } = useAuth();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState({
    action: "",
    type: "",
  });
  const [isProcessing, setIsProcessing] = useState(false);

  // Get basket name from basketDetails
  const basketName = basketDetails?.data?.symbol || "Loading...";

  // Separate states for buy and sell orders, but without price field
  const [buyOrderDetails, setBuyOrderDetails] = useState({
    amount: "",
    total: "",
  });

  const [sellOrderDetails, setSellOrderDetails] = useState({
    amount: "",
    total: "",
  });

  // Add new state for currency selection
  const [spendCurrency, setSpendCurrency] = useState("USD");
  const [receiveCurrency, setReceiveCurrency] = useState(basketName);

  // Add new states for validation
  const [minimumAmount, setMinimumAmount] = useState(null);
  const [validationError, setValidationError] = useState("");

  // Function to switch currencies
  const switchCurrencies = () => {
    setSpendCurrency(receiveCurrency);
    setReceiveCurrency(spendCurrency);
    // Reset amounts when switching
    setBuyOrderDetails({ amount: "", total: "" });
  };

  // Update totals when market data or amounts change
  useEffect(() => {
    // If spending USDT, calculate how much basket we'll receive
    if (spendCurrency === "USD") {
      setBuyOrderDetails((prev) => ({
        ...prev,
        total: prev.amount
          ? (parseFloat(prev.amount) / parseFloat(marketData.bestAsk)).toFixed(
              8
            )
          : "",
      }));
    }
    // If spending basket, calculate how much USDT we'll receive
    else {
      setBuyOrderDetails((prev) => ({
        ...prev,
        total: prev.amount
          ? (parseFloat(prev.amount) * parseFloat(marketData.bestAsk)).toFixed(
              2
            )
          : "",
      }));
    }
  }, [marketData.bestAsk, buyOrderDetails.amount, spendCurrency]);

  // Add these formatting functions at the top of the component
  const formatNumberWithCommas = (value) => {
    if (!value) return "";
    // Remove existing commas first
    const cleanValue = value.toString().replace(/,/g, "");
    const parts = cleanValue.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  const unformatNumber = (value) => {
    if (!value) return "";
    return value.toString().replace(/,/g, "");
  };

  // Function to fetch minimum amount
  const fetchMinimumAmount = async (type, amount) => {
    try {
      const token = await getToken({ template: "optima" });
      const endpoint = `https://clownfish-app-x4ynf.ondigitalocean.app/trades/calculate/${type.toLowerCase()}`;

      const payload =
        type.toLowerCase() === "buy"
          ? {
              basket_id: selectedBasketId,
              stable_amount: parseFloat(amount || 0),
            }
          : {
              basket_id: selectedBasketId,
              basket_tokens: parseFloat(amount || 0),
            };

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (!response.ok) {
        console.error("Failed to fetch minimum amount:", data.message);
        return null;
      }

      // Extract the minimum amount from the response
      const minAmount =
        type.toLowerCase() === "buy"
          ? data.data.minimumRequirements.suggestedMinimumUSDT
          : data.data.minimumRequirements.suggestedBasketUnits;

      console.log("Fetched minimum amount:", minAmount); // Debug log
      return minAmount;
    } catch (error) {
      console.error("Error fetching minimum amount:", error);
      return null;
    }
  };

  // Add new useEffect for periodic minimum amount refresh
  useEffect(() => {
    const fetchAndUpdateMinAmount = async () => {
      const type = spendCurrency === "USD" ? "buy" : "sell";
      const amount = buyOrderDetails.amount || "0";
      const minAmount = await fetchMinimumAmount(type, amount);
      console.log("Setting minimum amount:", minAmount); // Debug log
      if (minAmount !== null) {
        setMinimumAmount(minAmount);
      }
    };

    fetchAndUpdateMinAmount();

    const intervalId = setInterval(fetchAndUpdateMinAmount, 15000);

    return () => clearInterval(intervalId);
  }, [spendCurrency, selectedBasketId, buyOrderDetails.amount]); // Added buyOrderDetails.amount

  // Update handleAmountChange to remove validation message
  const handleAmountChange = async (e, side) => {
    let value = unformatNumber(e.target.value);

    // Only proceed if the value is a valid number or empty
    if (value === "" || !isNaN(value)) {
      // Check if the entered amount exceeds the user's balance
      if (value !== "" && parseFloat(value) > 0) {
        const availableBalance =
          spendCurrency === "USD"
            ? parseFloat(balances.usdc)
            : parseFloat(balances.basket);

        // If value is greater than balance, set it to the max balance instead
        if (parseFloat(value) > availableBalance) {
          value = availableBalance.toString();
        }
      }

      if (side === "buy") {
        if (spendCurrency === "USD") {
          setBuyOrderDetails({
            amount: value,
            total: value
              ? (parseFloat(value) / parseFloat(marketData.bestAsk)).toFixed(8)
              : "",
          });
        } else {
          setBuyOrderDetails({
            amount: value,
            total: value
              ? (parseFloat(value) * parseFloat(marketData.bestAsk)).toFixed(2)
              : "",
          });
        }
      }
    }
  };

  const handlePercentageClick = (percentage, side) => {
    if (side === "buy") {
      if (spendCurrency === "USD") {
        const amount = ((balances.usdc * percentage) / 100).toFixed(2);
        setBuyOrderDetails({
          amount,
          total: (parseFloat(amount) / parseFloat(marketData.bestAsk)).toFixed(
            8
          ),
        });
      } else {
        const amount = ((balances.basket * percentage) / 100).toFixed(8);
        setBuyOrderDetails({
          amount,
          total: (parseFloat(amount) * parseFloat(marketData.bestAsk)).toFixed(
            2
          ),
        });
      }
    }
  };

  // Update handleShowConfirmation to include KYC check
  const handleShowConfirmation = async (action, type) => {
    // If it's a sell action, show the alert message
    if (action === "SELL") {
      alert(
        "Sell orders are currently unavailable. Please check back later. If you need assistance, feel free to reach out to support."
      );
      return;
    }

    // First check KYC status
    const isKycVerified = await checkKycStatus();
    if (!isKycVerified) {
      return; // KYC modal will be shown by the Exchange component
    }

    // Check minimum amount before showing confirmation
    if (minimumAmount && parseFloat(buyOrderDetails.amount) < minimumAmount) {
      alert(
        `Minimum amount required: ${formatNumberWithCommas(minimumAmount)} ${
          spendCurrency === "USD" ? "USD" : basketName
        }`
      );
      return;
    }

    // Otherwise proceed with the original buy logic
    setConfirmationDetails({ action, type });
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleConfirm = async () => {
    try {
      setIsProcessing(true);
      const token = await getToken({ template: "optima" });
      const orderType = spendCurrency === "USD" ? "buy" : "sell";
      const amount = buyOrderDetails.amount;

      const endpoint = `https://clownfish-app-x4ynf.ondigitalocean.app/trades/${orderType}`;

      // Different payload structure for buy vs sell
      const payload =
        orderType === "buy"
          ? {
              stable_amount: parseFloat(amount),
              basket_id: parseFloat(selectedBasketId),
            }
          : {
              basket_tokens: parseFloat(amount),
              basket_id: parseFloat(selectedBasketId),
            };

      console.log(`Sending ${orderType} request with payload:`, payload);

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();
      console.log("Response status:", response.status);
      console.log("Response data:", responseData);

      if (!response.ok) {
        console.error("Detailed error info:", {
          status: response.status,
          statusText: response.statusText,
          responseData,
          payload,
          orderType,
          selectedBasketId,
        });
        throw new Error(
          `Trade failed: ${responseData.message || "Unknown error"}`
        );
      }

      // Reset the form after successful trade
      setBuyOrderDetails({ amount: "", total: "" });
      setShowConfirmation(false);

      // Call the onTradeResponse prop with success
      onTradeResponse(true);
    } catch (error) {
      console.error("Trade error:", error);
      // Call the onTradeResponse prop with failure
      onTradeResponse(false);
    } finally {
      setIsProcessing(false);
    }
  };

  const styles = {
    container: {
      backgroundColor: "#fff",
      borderRadius: "12px",
      padding: "24px",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.05)",
    },
    section: {
      border: "1px solid #e0e3eb",
      borderRadius: "12px",
      padding: "20px",
      marginBottom: "16px",
    },
    inputGroup: {
      marginBottom: "24px",
    },
    label: {
      fontSize: "16px",
      fontWeight: "500",
      marginBottom: "12px",
      color: "#333",
    },
    input: {
      width: "100%",
      padding: "16px",
      fontSize: "24px",
      border: "1px solid #e0e3eb",
      borderRadius: "8px",
      outline: "none",
      transition: "border-color 0.2s",
      paddingRight: "120px",
    },
    inputContainer: {
      position: "relative",
      width: "100%",
      marginBottom: "12px",
    },
    currencyButton: {
      position: "absolute",
      right: "8px",
      top: "50%",
      transform: "translateY(-50%)",
      padding: "8px 16px",
      backgroundColor: "#f8f9fa",
      border: "1px solid #e0e3eb",
      borderRadius: "6px",
      cursor: "pointer",
      color: "#333",
      fontSize: "14px",
      fontWeight: "500",
      display: "flex",
      alignItems: "center",
      gap: "4px",
    },
    dropdownArrow: {
      marginLeft: "4px",
      fontSize: "12px",
    },
    percentageContainer: {
      display: "flex",
      gap: "8px",
      marginTop: "12px",
    },
    percentButton: {
      padding: "6px 12px",
      border: "1px solid #e0e3eb",
      borderRadius: "6px",
      backgroundColor: "transparent",
      color: "#666",
      cursor: "pointer",
      transition: "all 0.2s",
    },
    activePercentButton: {
      backgroundColor: "#f0f3fa",
      color: "#2962ff",
      borderColor: "#2962ff",
    },
    conversionRate: {
      fontSize: "14px",
      color: "#666",
      marginTop: "8px",
    },
    convertButton: {
      width: "100%",
      padding: "16px",
      backgroundColor: "#2962ff",
      color: "white",
      border: "none",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: "500",
      cursor: "pointer",
      transition: "background-color 0.2s",
    },
    arrowContainer: {
      display: "flex",
      justifyContent: "center",
      margin: "20px 0",
      color: "#666",
    },
    balanceText: {
      fontSize: "14px",
      color: "#666",
      marginTop: "8px",
    },
  };

  return (
    <div style={{ ...styles.container, position: "relative" }}>
      {!isTradeable ? (
        <>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "51%",
              transform: "translate(-50%, -50%)",
              zIndex: 2,
              textAlign: "center",
              backgroundColor: "rgba(255, 255, 255, 0.95)",
              padding: "20px 40px",
              borderRadius: "10px",
              boxShadow:
                "0 8px 16px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.1)",
              width: "auto",
              minWidth: "200px",
            }}
          >
            <h3 style={{ color: "#000000", marginBottom: "10px" }}>
              COMING SOON
            </h3>
            <p style={{ color: "#000000" }}>
              This basket will be available for trading soon!
            </p>
          </div>
          <div
            style={{
              ...styles.section,
              opacity: 0.5,
              pointerEvents: "none",
              backgroundColor: "#f8f9fa",
            }}
          >
            {/* Original Spot component content */}
            <div style={{ ...styles.section, backgroundColor: "#f8f9fa" }}>
              {/* Spend Section */}
              <div style={styles.inputGroup}>
                <div style={{ ...styles.label, color: "#000000" }}>Spend</div>
                <div style={styles.inputContainer}>
                  <input
                    type="text"
                    style={{
                      ...styles.input,
                      color: "#000000",
                      borderColor:
                        buyOrderDetails.amount &&
                        parseFloat(buyOrderDetails.amount) > 0 &&
                        parseFloat(buyOrderDetails.amount) ===
                          parseFloat(
                            spendCurrency === "USD"
                              ? balances.usdc
                              : balances.basket
                          )
                          ? "#00C087"
                          : "#e0e3eb",
                    }}
                    value={formatNumberWithCommas(buyOrderDetails.amount)}
                    onChange={(e) => handleAmountChange(e, "buy")}
                    placeholder="0"
                  />
                  <button
                    style={{ ...styles.currencyButton, color: "#000000" }}
                    onClick={switchCurrencies}
                  >
                    {spendCurrency === "USD" ? "USD" : basketName}
                    <span style={styles.dropdownArrow}>▼</span>
                  </button>
                </div>
                <div style={{ ...styles.balanceText, color: "#000000" }}>
                  Balance:{" "}
                  {isProcessing
                    ? "Loading..."
                    : spendCurrency === "USD"
                    ? `${formatNumberWithCommas(balances.usdc)} USD`
                    : `${formatNumberWithCommas(
                        balances.basket
                      )} ${basketName}`}
                  {minimumAmount && (
                    <span style={{ marginLeft: "8px" }}>
                      | Min. amount:{" "}
                      {formatNumberWithCommas(minimumAmount.toFixed(2))}{" "}
                      {spendCurrency === "USD" ? "USD" : basketName}
                    </span>
                  )}
                  {buyOrderDetails.amount &&
                    parseFloat(buyOrderDetails.amount) > 0 &&
                    parseFloat(buyOrderDetails.amount) ===
                      parseFloat(
                        spendCurrency === "USD"
                          ? balances.usdc
                          : balances.basket
                      ) && (
                      <span style={{ marginLeft: "8px", color: "#00C087" }}>
                        | Maximum amount
                      </span>
                    )}
                </div>
                <div style={styles.percentageContainer}>
                  {["10%", "20%", "50%", "100%"].map((text, index) => {
                    const percentValue = [10, 20, 50, 100][index];
                    const expectedAmount =
                      spendCurrency === "USD"
                        ? ((balances.usdc * percentValue) / 100).toFixed(2)
                        : ((balances.basket * percentValue) / 100).toFixed(8);

                    return (
                      <button
                        key={text}
                        onClick={() =>
                          handlePercentageClick(percentValue, "buy")
                        }
                        style={{
                          ...styles.percentButton,
                          color: "#000000",
                          ...(buyOrderDetails.amount === expectedAmount
                            ? styles.activePercentButton
                            : {}),
                        }}
                      >
                        {text}
                      </button>
                    );
                  })}
                </div>
              </div>

              {/* Arrow Separator */}
              <div style={{ ...styles.arrowContainer, color: "#000000" }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M12 4L12 20M12 20L18 14M12 20L6 14"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>

              {/* To Receive Section */}
              <div style={styles.inputGroup}>
                <div style={{ ...styles.label, color: "#000000" }}>
                  To receive
                </div>
                <div style={styles.inputContainer}>
                  <input
                    type="text"
                    style={{
                      ...styles.input,
                      backgroundColor: "#f8f9fa",
                      color: "#000000",
                    }}
                    value={formatNumberWithCommas(buyOrderDetails.total)}
                    readOnly
                    placeholder="0"
                  />
                  <button
                    style={{
                      ...styles.currencyButton,
                      backgroundColor: "#f8f9fa",
                      color: "#000000",
                    }}
                    onClick={switchCurrencies}
                  >
                    {receiveCurrency === "USD" ? "USD" : basketName}
                    <span style={styles.dropdownArrow}>▼</span>
                  </button>
                </div>
                <div style={{ ...styles.conversionRate, color: "#000000" }}>
                  {spendCurrency === "USD" ? (
                    <>
                      1 USD ≈{" "}
                      {formatNumberWithCommas(
                        (1 / parseFloat(marketData.bestAsk)).toFixed(2)
                      )}{" "}
                      {basketName} (Buy)
                    </>
                  ) : (
                    <>
                      1 {basketName} ≈{" "}
                      {formatNumberWithCommas(
                        parseFloat(marketData.bestBid).toFixed(2)
                      )}{" "}
                      USD (Sell)
                    </>
                  )}
                </div>
              </div>

              <button
                style={{
                  ...styles.convertButton,
                  backgroundColor:
                    spendCurrency === "USD" ? "#00C087" : "#FF3B69",
                  opacity:
                    !buyOrderDetails.amount ||
                    parseFloat(buyOrderDetails.amount) <= 0 ||
                    validationError ||
                    (minimumAmount &&
                      parseFloat(buyOrderDetails.amount) < minimumAmount)
                      ? 0.5
                      : 1,
                  cursor:
                    !buyOrderDetails.amount ||
                    parseFloat(buyOrderDetails.amount) <= 0 ||
                    validationError ||
                    (minimumAmount &&
                      parseFloat(buyOrderDetails.amount) < minimumAmount)
                      ? "not-allowed"
                      : "pointer",
                }}
                onClick={() =>
                  handleShowConfirmation(
                    spendCurrency === "USD" ? "BUY" : "SELL",
                    basketName
                  )
                }
                disabled={
                  !buyOrderDetails.amount ||
                  parseFloat(buyOrderDetails.amount) <= 0 ||
                  validationError ||
                  (minimumAmount &&
                    parseFloat(buyOrderDetails.amount) < minimumAmount)
                }
              >
                {spendCurrency === "USD" ? "Buy Basket" : "Sell Basket"}{" "}
                {basketName}
              </button>
            </div>
          </div>
        </>
      ) : (
        <div style={styles.section}>
          {/* Spend Section */}
          <div style={styles.inputGroup}>
            <div style={{ ...styles.label, color: "#000000" }}>Spend</div>
            <div style={styles.inputContainer}>
              <input
                type="text"
                style={{
                  ...styles.input,
                  color: "#000000",
                  borderColor:
                    buyOrderDetails.amount &&
                    parseFloat(buyOrderDetails.amount) > 0 &&
                    parseFloat(buyOrderDetails.amount) ===
                      parseFloat(
                        spendCurrency === "USD"
                          ? balances.usdc
                          : balances.basket
                      )
                      ? "#00C087"
                      : "#e0e3eb",
                }}
                value={formatNumberWithCommas(buyOrderDetails.amount)}
                onChange={(e) => handleAmountChange(e, "buy")}
                placeholder="0"
              />
              <button style={styles.currencyButton} onClick={switchCurrencies}>
                {spendCurrency === "USD" ? "USD" : basketName}
                <span style={styles.dropdownArrow}>▼</span>
              </button>
            </div>
            <div style={{ ...styles.balanceText, color: "#000000" }}>
              Balance:{" "}
              {isProcessing
                ? "Loading..."
                : spendCurrency === "USD"
                ? `${formatNumberWithCommas(balances.usdc)} USD`
                : `${formatNumberWithCommas(balances.basket)} ${basketName}`}
              {minimumAmount && (
                <span style={{ marginLeft: "8px" }}>
                  | Min. amount:{" "}
                  {formatNumberWithCommas(minimumAmount.toFixed(2))}{" "}
                  {spendCurrency === "USD" ? "USD" : basketName}
                </span>
              )}
              {buyOrderDetails.amount &&
                parseFloat(buyOrderDetails.amount) > 0 &&
                parseFloat(buyOrderDetails.amount) ===
                  parseFloat(
                    spendCurrency === "USD" ? balances.usdc : balances.basket
                  ) && (
                  <span style={{ marginLeft: "8px", color: "#00C087" }}>
                    | Maximum amount
                  </span>
                )}
            </div>
            <div style={styles.percentageContainer}>
              {["10%", "20%", "50%", "100%"].map((text, index) => {
                const percentValue = [10, 20, 50, 100][index];
                const expectedAmount =
                  spendCurrency === "USD"
                    ? ((balances.usdc * percentValue) / 100).toFixed(2)
                    : ((balances.basket * percentValue) / 100).toFixed(8);

                return (
                  <button
                    key={text}
                    onClick={() => handlePercentageClick(percentValue, "buy")}
                    style={{
                      ...styles.percentButton,
                      ...(buyOrderDetails.amount === expectedAmount
                        ? styles.activePercentButton
                        : {}),
                    }}
                  >
                    {text}
                  </button>
                );
              })}
            </div>
          </div>

          {/* Arrow Separator */}
          <div style={{ ...styles.arrowContainer, color: "#000000" }}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M12 4L12 20M12 20L18 14M12 20L6 14"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>

          {/* To Receive Section */}
          <div style={styles.inputGroup}>
            <div style={{ ...styles.label, color: "#000000" }}>To receive</div>
            <div style={styles.inputContainer}>
              <input
                type="text"
                style={{
                  ...styles.input,
                  backgroundColor: "#f8f9fa",
                  color: "#000000",
                }}
                value={formatNumberWithCommas(buyOrderDetails.total)}
                readOnly
                placeholder="0"
              />
              <button
                style={{
                  ...styles.currencyButton,
                  backgroundColor: "#f8f9fa",
                  color: "#000000",
                }}
                onClick={switchCurrencies}
              >
                {receiveCurrency === "USD" ? "USD" : basketName}
                <span style={styles.dropdownArrow}>▼</span>
              </button>
            </div>
            <div style={{ ...styles.conversionRate, color: "#000000" }}>
              {spendCurrency === "USD" ? (
                <>
                  1 USD ≈{" "}
                  {formatNumberWithCommas(
                    (1 / parseFloat(marketData.bestAsk)).toFixed(2)
                  )}{" "}
                  {basketName} (Buy)
                </>
              ) : (
                <>
                  1 {basketName} ≈{" "}
                  {formatNumberWithCommas(
                    parseFloat(marketData.bestBid).toFixed(2)
                  )}{" "}
                  USD (Sell)
                </>
              )}
            </div>
          </div>

          <button
            style={{
              ...styles.convertButton,
              backgroundColor: spendCurrency === "USD" ? "#00C087" : "#FF3B69",
              opacity:
                !buyOrderDetails.amount ||
                parseFloat(buyOrderDetails.amount) <= 0 ||
                validationError ||
                (minimumAmount &&
                  parseFloat(buyOrderDetails.amount) < minimumAmount)
                  ? 0.5
                  : 1,
              cursor:
                !buyOrderDetails.amount ||
                parseFloat(buyOrderDetails.amount) <= 0 ||
                validationError ||
                (minimumAmount &&
                  parseFloat(buyOrderDetails.amount) < minimumAmount)
                  ? "not-allowed"
                  : "pointer",
            }}
            onClick={() =>
              handleShowConfirmation(
                spendCurrency === "USD" ? "BUY" : "SELL",
                basketName
              )
            }
            disabled={
              !buyOrderDetails.amount ||
              parseFloat(buyOrderDetails.amount) <= 0 ||
              validationError ||
              (minimumAmount &&
                parseFloat(buyOrderDetails.amount) < minimumAmount)
            }
          >
            {spendCurrency === "USD" ? "Buy Basket" : "Sell Basket"}{" "}
            {basketName}
          </button>
        </div>
      )}

      <ConfirmationPopup
        show={showConfirmation}
        onHide={handleCloseConfirmation}
        onConfirm={handleConfirm}
        title={`Confirm ${confirmationDetails.action} ${confirmationDetails.type}`}
        body={
          isProcessing ? (
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <p className="mt-2 text-black">Processing your order...</p>
            </div>
          ) : (
            <div className="text-black">
              <p>
                {`Are you sure you want to convert ${formatNumberWithCommas(
                  buyOrderDetails.amount
                )} ${
                  spendCurrency === "USD" ? "USD" : basketDetails?.data?.name
                } to ${formatNumberWithCommas(buyOrderDetails.total)} ${
                  spendCurrency === "USD" ? basketDetails?.data?.name : "USD"
                }?`}
              </p>
            </div>
          )
        }
        confirmButtonDisabled={isProcessing}
        confirmButtonText={isProcessing ? "Processing..." : "Confirm"}
        cancelButtonText={isProcessing ? "OK" : "Cancel"}
      />
    </div>
  );
}

// Add prop types validation if you're using PropTypes
Spot.defaultProps = {
  onTradeResponse: () => {}, // Default empty function if prop is not provided
  isTradeable: false, // Default to false for safety
};

export default Spot;
