import React from "react";

function TradeHistory({ trades }) {
  console.log(trades.trades, "TIQ");
  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title mb-0">Recent Transactions</h5>
      </div>
      <div className="card-body">
        {trades && trades.trades && trades.trades.length > 0 ? (
          <div className="table-responsive">
            <table className="table table-hover align-middle mb-0">
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {trades.trades.map((trade) => (
                  <tr key={trade.orderId}>
                    <td data-label="Order ID">{trade.orderId.slice(0, 8)}</td>
                    <td data-label="Amount">${trade.amount}</td>
                    <td data-label="Status">
                      <span
                        className={`badge ${
                          trade.status === "completed"
                            ? "bg-success"
                            : "bg-danger"
                        }`}
                      >
                        {trade.status.toUpperCase()}
                      </span>
                    </td>
                    <td data-label="Date">
                      {new Date(trade.createdAt).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-center py-4">
            <p className="mb-0">There are no records to display</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default TradeHistory;
