"use client";

import React, { useState, useEffect } from "react";
import BalanceDetail from "../../Component/DashboardOne/BalanceDetail";
import BalanceDetails from "../../Component/WalletPage/BalanceDetails";
import Deposit from "../../Component/WalletPage/Deposit";
import Transaction from "../../Component/WalletPage/Transaction";
import Transfer from "../../Component/WalletPage/Transfer";
import WithdrawCrypto from "../../Component/WalletPage/WithdrawCrypto";
import PageTitle from "../../Component/Comman/PageTitle";
import { useAuth, useUser } from "@clerk/clerk-react";
import axios from "axios";
import { testAllEndpoints } from "../../Hooks/apiTester";

// Wallet Page
function WalletPage() {
  const [activeTab, setActiveTab] = useState("balance"); // Options: 'balance', 'withdraw', 'deposit'

  const { isSignedIn, isLoaded, getToken } = useAuth();
  const { user } = useUser();
  const [token, setToken] = useState(null);
  const [credits, setCredits] = useState(null);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [isProfileOpening, setIsProfileOpening] = useState(false);

  // Hardcoded basket ID for now and price for it
  const HARDCODED_BASKET_ID = "1aec9f6c-1e06-4c18-b0f5-0d43d4fbccec";
  const [marketData, setMarketData] = useState({
    bestBid: "0",
    bestAsk: "0",
    lastPrice: "0",
    highPrice24h: "0",
    lowPrice24h: "0",
    volume24h: "0",
  });

  // useEffect(() => {
  //   testAllEndpoints(getToken);
  // }, [getToken]);

  // New url https://clownfish-app-x4ynf.ondigitalocean.app/

  // Add check for web3 wallet
  const hasConnectedWallet = user?.web3Wallets && user.web3Wallets.length > 0;

  useEffect(() => {
    const fetchData = async () => {
      if (isSignedIn) {
        try {
          const token = await getToken({ template: "optima" });
          setToken(token);

          const response = await axios.get(
            "https://clownfish-app-x4ynf.ondigitalocean.app/users/credits",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const creditsData = response.data;
          setCredits(creditsData);
        } catch (err) {
          console.error("Error fetching data:", err);
          console.error("Full error details:", {
            message: err.message,
            stack: err.stack,
          });
        }
      }
    };

    // Initial fetch
    fetchData();

    // Set up interval for periodic fetching
    const intervalId = setInterval(fetchData, 3000); // 3000ms = 3 seconds

    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, [isSignedIn, getToken]);

  useEffect(() => {
    // Only show modal if user is signed in, hasn't connected wallet,
    // and we're not in the process of opening the profile
    if (
      isSignedIn &&
      !hasConnectedWallet &&
      !showWalletModal &&
      !isProfileOpening
    ) {
      setShowWalletModal(true);
    }
  }, [isSignedIn, hasConnectedWallet, showWalletModal, isProfileOpening]);

  const renderActiveComponent = () => {
    switch (activeTab) {
      case "balance":
        return <BalanceDetails credits={credits} marketData={marketData} />;
      case "deposit":
        return <Deposit />;
      case "withdraw":
        return <WithdrawCrypto />;

      default:
        return <BalanceDetails credits={credits} marketData={marketData} />;
    }
  };

  const openClerkProfile = () => {
    console.log("Button clicked, closing modal..."); // Debug log
    setShowWalletModal(false); // Close the modal immediately
    setIsProfileOpening(true); // Set flag to prevent modal from showing

    // Add a small delay before opening Clerk profile
    setTimeout(() => {
      if (typeof window.Clerk !== "undefined") {
        window.Clerk.openUserProfile();

        // Set a timeout to recheck wallet connection after 2 minutes
        setTimeout(() => {
          setIsProfileOpening(false); // Reset flag
          if (!hasConnectedWallet) {
            console.log("No wallet connected after timeout, showing modal..."); // Debug log
            setShowWalletModal(true);
          }
        }, 120000); // 120000ms = 2 minutes
      }
    }, 100);
  };

  // Update WebSocket connection to use secure WSS
  useEffect(() => {
    // const ws = new WebSocket(
    //   `wss://bitgo-microservice-nipyw.ondigitalocean.app/baskets/${HARDCODED_BASKET_ID}/prices/stream`
    // );

    const ws = new WebSocket(
      "wss://clownfish-app-x4ynf.ondigitalocean.app/ws/prices"
    );

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "PRICE_UPDATE") {
        setMarketData((prevData) => ({
          ...prevData,
          bestBid: data.data.bid.toFixed(2),
          bestAsk: data.data.ask.toFixed(2),
          lastPrice: ((data.data.bid + data.data.ask) / 2).toFixed(2),
        }));
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      ws.close();
    };
  }, []);

  return (
    <div className="container-xxl">
      {/* Wallet Connection Modal */}
      {showWalletModal && (
        <div
          className="modal show d-block"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
          onClick={(e) => {
            // Close modal when clicking outside
            if (e.target === e.currentTarget) {
              setShowWalletModal(false);
            }
          }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Connect Your Wallet</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowWalletModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <p>Please connect your wallet to access all features.</p>
                <button className="btn btn-primary" onClick={openClerkProfile}>
                  Connect Wallet
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Show main content only if wallet is connected */}
      {(!isSignedIn || hasConnectedWallet) && (
        <div className="row g-3 mb-3 row-deck">
          <div
            className="border-bottom d-flex py-3 mb-3"
            style={{ color: "#002040FF" }}
          >
            <h4>Wallet</h4>
          </div>

          {/* Tab Navigation */}
          <div className="d-flex gap-3 mb-3">
            <button
              className={`btn ${
                activeTab === "balance" ? "btn-primary" : "btn-outline-primary"
              }`}
              onClick={() => setActiveTab("balance")}
            >
              Balance Details
            </button>
            <button
              className={`btn ${
                activeTab === "deposit" ? "btn-primary" : "btn-outline-primary"
              }`}
              onClick={() => setActiveTab("deposit")}
            >
              Deposit
            </button>
            <button
              className={`btn ${
                activeTab === "withdraw" ? "btn-primary" : "btn-outline-primary"
              }`}
              onClick={() => setActiveTab("withdraw")}
            >
              Withdraw
            </button>
          </div>

          {/* Component Container with consistent styling */}
          <div className="card">
            <div className="card-body p-4">{renderActiveComponent()}</div>
            <Transaction activeTab={activeTab} />
          </div>
        </div>
      )}

      {/* <div className="row"></div> */}
    </div>
  );
}
export default WalletPage;
