import React, { useState, useEffect } from "react";
import { useAuth, useUser } from "@clerk/clerk-react";
import axios from "axios";
import { ethers } from "ethers";
import { useSearchParams } from "react-router-dom";
import PageTitle from "../../Component/Comman/PageTitle";
import Chart from "../../Component/Exchange/Chart";
import OrderBook from "../../Component/Exchange/OrderBook";
import Spot from "../../Component/Exchange/Spot";
import SpotTradStatus from "../../Component/Exchange/SpotTradStatus";
import TradeHistory from "./TradeHistory";
import "../../styles/Exchange.mobile.css";

// Add this new component near the top of the file, after imports
const TradeResponseModal = ({ isOpen, onClose, success }) => {
  if (!isOpen) return null;

  return (
    <div
      className="modal show d-block"
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Trade Status</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            <p>
              {success
                ? "Trade executed successfully!"
                : "Trade execution failed"}
            </p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={onClose}>
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Add this new component right here in the file
const BasketInfo = ({
  basketDetails,
  baskets,
  selectedBasketId,
  marketData,
  onBasketSelect,
}) => {
  const formatNumber = (num) => {
    const number = Number(num);
    return Math.floor(number * 100) / 100;
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const [showWeights, setShowWeights] = useState(false);

  return (
    <div className="row g-3 mb-3">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-start">
              <div className="flex-grow-1" style={{ position: "relative" }}>
                <div
                  className="d-flex justify-content-end align-items-center mb-3"
                  style={{ position: "absolute", right: 0, zIndex: 1 }}
                >
                  <select
                    className="form-select"
                    style={{ width: "auto" }}
                    value={selectedBasketId}
                    onChange={(e) => onBasketSelect(e.target.value)}
                  >
                    {baskets.map((basket) => (
                      <option key={basket.id} value={basket.id}>
                        {basket.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <h6
                      className="mb-2"
                      style={{ textDecoration: "underline" }}
                    >
                      Description
                    </h6>
                    <p className="mb-0">
                      {basketDetails?.data?.description ||
                        "Loading description..."}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-2">
                      <h6 style={{ textDecoration: "underline" }}>Details</h6>
                      <table className="table table-sm table-borderless mb-0">
                        <tbody>
                          <tr>
                            <td>
                              <p className="mb-0">
                                <strong>Price:</strong> $
                                {formatNumber(marketData?.lastPrice || 0)} USD
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="mb-0">
                                <strong>Initial Price:</strong> $
                                {basketDetails?.data?.initialPrice || 0} USD
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="mb-0">
                                <strong>Start Date:</strong>{" "}
                                {formatDate(basketDetails?.data?.startDate)}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="mb-0">
                                <strong>Contract Address:</strong>{" "}
                                <a
                                  href={`https://151.routescan.io/address/${basketDetails?.data?.addresses?.[151]}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-decoration-none"
                                >
                                  <small className="text-muted">
                                    {basketDetails?.data?.addresses?.[151]?.slice(
                                      0,
                                      6
                                    )}
                                    ...
                                    {basketDetails?.data?.addresses?.[151]?.slice(
                                      -4
                                    )}
                                  </small>
                                </a>
                                <div
                                  className="d-inline-block ms-2 info-tooltip"
                                  style={{ position: "relative" }}
                                  onMouseEnter={() => setShowWeights(true)}
                                  onMouseLeave={() => setShowWeights(false)}
                                >
                                  <i
                                    className="bi bi-info-circle"
                                    style={{
                                      fontSize: "0.9rem",
                                      color: "#6c757d",
                                      cursor: "help",
                                    }}
                                  ></i>
                                  {showWeights &&
                                    basketDetails?.data?.tokens && (
                                      <div
                                        className="weights-popup"
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "100%",
                                          transform: "translateY(-50%)",
                                          backgroundColor: "white",
                                          border: "1px solid #dee2e6",
                                          borderRadius: "4px",
                                          padding: "8px 12px",
                                          boxShadow:
                                            "0 2px 4px rgba(0,0,0,0.1)",
                                          zIndex: 1000,
                                          width: "max-content",
                                          minWidth: "200px",
                                          marginLeft: "8px",
                                        }}
                                      >
                                        <h6
                                          className="mb-2"
                                          style={{
                                            fontSize: "0.85rem",
                                            color: "#495057",
                                          }}
                                        >
                                          Basket Weights
                                        </h6>
                                        <div>
                                          {basketDetails.data.tokens.map(
                                            (token, index) => (
                                              <div
                                                key={index}
                                                className="d-flex justify-content-between align-items-center mb-1"
                                              >
                                                <span
                                                  style={{
                                                    fontSize: "0.85rem",
                                                  }}
                                                >
                                                  <strong>
                                                    {token.symbol}
                                                  </strong>
                                                </span>
                                                <span
                                                  style={{
                                                    fontSize: "0.85rem",
                                                    marginLeft: "12px",
                                                    color: "#6c757d",
                                                  }}
                                                >
                                                  {(token.weight * 100).toFixed(
                                                    2
                                                  )}
                                                  %
                                                </span>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Trade Page
function Exchange() {
  const { getToken } = useAuth();
  const { user } = useUser();
  const [searchParams] = useSearchParams();
  const basketIdFromUrl = searchParams.get("basket") || "1";
  const [baskets, setBaskets] = useState([]);
  const [basketDetails, setBasketDetails] = useState(null);
  const [selectedBasketId, setSelectedBasketId] = useState(basketIdFromUrl);
  const [depositAmount, setDepositAmount] = useState("");
  const [optimaWalletAddress, setOptimaWalletAddress] = useState(null);
  const [userBalances, setUserBalances] = useState({
    usdc: "0",
    basket: "0",
  });
  const [marketData, setMarketData] = useState({
    bestBid: "0",
    bestAsk: "0",
    spotPrice: "0",
    lastPrice: "0",
    highPrice24h: "0",
    lowPrice24h: "0",
    volume24h: "0",
  });
  const [trades, setTrades] = useState([]);
  const [isBalanceLoading, setIsBalanceLoading] = useState(true);
  const [showTradeResponse, setShowTradeResponse] = useState(false);
  const [tradeSuccess, setTradeSuccess] = useState(false);
  const [kycMessage, setKycMessage] = useState("");
  const [kycRedirectUrl, setKycRedirectUrl] = useState("");
  const [showKycModal, setShowKycModal] = useState(false);
  const [historicalPrices, setHistoricalPrices] = useState([]);

  const fetchBasketDetails = async (basketId) => {
    try {
      const token = await getToken({ template: "optima" });
      const response = await axios.get(
        `https://clownfish-app-x4ynf.ondigitalocean.app/baskets/${basketId}/overview`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data, "BASKET DETAILS");
      setBasketDetails(response.data);
    } catch (error) {
      console.error("Error fetching basket details:", error);
    }
  };

  {
    /* Fetching baskets */
  }
  useEffect(() => {
    const fetchBaskets = async () => {
      try {
        const token = await getToken({ template: "optima" });
        const response = await axios.get(
          "https://clownfish-app-x4ynf.ondigitalocean.app/baskets",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const basketsData = response.data.data || [];
        setBaskets(basketsData);
      } catch (error) {
        console.error("Error fetching baskets:", error);
        setBaskets([]);
      }
    };
    fetchBaskets();
  }, [getToken]);

  {
    /* Fetching optima wallet address */
  }
  useEffect(() => {
    const fetchOptimaWallet = async () => {
      try {
        const token = await getToken({ template: "optima" });
        const response = await fetch(
          "https://clownfish-app-x4ynf.ondigitalocean.app/users/account",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setOptimaWalletAddress(data.data.wallet);
      } catch (error) {
        console.error("Error fetching Optima wallet:", error);
      }
    };

    fetchOptimaWallet();
  }, [getToken]);

  // Update WebSocket connection to handle new data format
  useEffect(() => {
    const ws = new WebSocket(
      `wss://clownfish-app-x4ynf.ondigitalocean.app/ws/prices`
    );

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "basketUpdate" && data.basketId === selectedBasketId) {
        setMarketData((prevData) => ({
          ...prevData,
          bestBid: data.data.bidPrice.toFixed(2),
          bestAsk: data.data.askPrice.toFixed(2),
          spotPrice: data.data.spotPrice.toFixed(2),
          lastPrice: data.data.spotPrice, // Using spotPrice as lastPrice
        }));
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    // Add initial price fetch when basket changes
    const fetchInitialPrice = async () => {
      try {
        const token = await getToken({ template: "optima" });
        const response = await axios.get(
          `https://clownfish-app-x4ynf.ondigitalocean.app/baskets/${selectedBasketId}/price`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const price = response.data.data.price;
        setMarketData((prevData) => ({
          ...prevData,
          bestBid: price.toFixed(2),
          bestAsk: price.toFixed(2),
          spotPrice: price.toFixed(2),
          lastPrice: price,
        }));
      } catch (error) {
        console.error("Error fetching initial price:", error);
      }
    };

    fetchInitialPrice();

    return () => {
      ws.close();
    };
  }, [selectedBasketId, getToken]);

  // Add function to fetch user credits
  const fetchUserCredits = async () => {
    try {
      const token = await getToken({ template: "optima" });
      const response = await axios.get(
        "https://clownfish-app-x4ynf.ondigitalocean.app/users/credits",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setUserBalances({
          usdc: parseFloat(response.data.data.formatted.USDC).toFixed(2),
          basket: response.data.data.formatted.baskets["1"] || "0",
        });
      }
    } catch (error) {
      console.error("Error fetching user credits:", error);
    }
  };

  // Add useEffect to fetch credits
  useEffect(() => {
    // Set initial loading state
    setIsBalanceLoading(true);

    // Set timer to remove loading state after 2 seconds
    const loadingTimer = setTimeout(() => {
      setIsBalanceLoading(false);
    }, 2000);

    fetchUserCredits();
    const interval = setInterval(fetchUserCredits, 30000);

    // Clean up both the interval and the timer
    return () => {
      clearInterval(interval);
      clearTimeout(loadingTimer);
    };
  }, []);

  // Add useEffect to fetch basket details when basket changes
  useEffect(() => {
    fetchBasketDetails(selectedBasketId);
  }, [selectedBasketId]);

  // Add this new effect to fetch trades
  useEffect(() => {
    const fetchTrades = async () => {
      try {
        const token = await getToken({ template: "optima" });
        const response = await axios.get(
          "https://clownfish-app-x4ynf.ondigitalocean.app/trades",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTrades(response.data.data || []);
      } catch (error) {
        console.error("Error fetching trades:", error);
      }
    };

    fetchTrades();
    // Refresh trades every 3 seconds
    const interval = setInterval(fetchTrades, 3000);
    return () => clearInterval(interval);
  }, [getToken]);

  // KYC check function
  const checkKycStatus = async () => {
    try {
      if (!user?.primaryWeb3Wallet?.web3Wallet) {
        console.error("No wallet address found");
        return false;
      }

      const token = await getToken({ template: "optima" });
      const response = await fetch(
        `https://clownfish-app-x4ynf.ondigitalocean.app/chains/151/kyc/${user.primaryWeb3Wallet.web3Wallet}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const kycData = await response.json();

      if (!kycData.data.isVerified) {
        setKycMessage(kycData.data.message);
        setKycRedirectUrl(kycData.data.redirectUrl);
        setShowKycModal(true);
        return false;
      }

      return true;
    } catch (error) {
      console.error("Error checking KYC status:", error);
      return false;
    }
  };

  // Add new function to fetch historical prices
  const fetchHistoricalPrices = async (basketId) => {
    try {
      const token = await getToken({ template: "optima" });
      const response = await axios.get(
        `https://clownfish-app-x4ynf.ondigitalocean.app/baskets/${basketId}/prices/hourly`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data, "HOURLY PRICES");
      // Format the data for the chart - using the timestamp and price from API
      // Exclude the last hour's data by slicing the array
      const formattedData = response.data.data.hourlyPrices
        .slice(0, -1) // Remove the last hour's data
        .map((item) => ({
          time: new Date(item.timestamp).getTime() / 1000, // Convert ISO string to Unix timestamp
          value: item.price,
        }));

      setHistoricalPrices(formattedData);
    } catch (error) {
      console.error("Error fetching hourly prices:", error);
    }
  };

  // Add useEffect to fetch historical prices when basket changes
  useEffect(() => {
    fetchHistoricalPrices(selectedBasketId);
    // Fetch hourly prices every minute since we have hourly data
    const interval = setInterval(
      () => fetchHistoricalPrices(selectedBasketId),
      60 * 1000
    );
    return () => clearInterval(interval);
  }, [selectedBasketId]);

  // Update useEffect to handle basket selection from URL
  useEffect(() => {
    if (basketIdFromUrl) {
      setSelectedBasketId(basketIdFromUrl);
      fetchBasketDetails(basketIdFromUrl);
      fetchHistoricalPrices(basketIdFromUrl);
    }
  }, [basketIdFromUrl]);

  return (
    <>
      <TradeResponseModal
        isOpen={showTradeResponse}
        onClose={() => setShowTradeResponse(false)}
        success={tradeSuccess}
      />

      <div className="body-header d-flex py-2 mb-2">
        <div className="container-xxl">
          <div
            className="border-bottom d-flex py-2 mb-2"
            style={{ color: "#002040FF" }}
          >
            <h4>Trade</h4>
          </div>
        </div>
      </div>

      <div className="container-xxl">
        <BasketInfo
          basketDetails={basketDetails}
          baskets={baskets}
          selectedBasketId={selectedBasketId}
          marketData={marketData}
          onBasketSelect={setSelectedBasketId}
        />

        <div className="row g-3 mb-3">
          <div
            className="col-md-12"
            style={{ padding: "10px", marginBottom: "-30px" }}
          >
            <Chart
              basketName={basketDetails?.data?.name || "Loading..."}
              basketDetails={basketDetails}
              marketData={marketData}
              historicalPrices={historicalPrices}
            />
          </div>
        </div>
        <div className="row g-3 mb-3">
          <div
            className="col-xxl-12"
            style={{ padding: "10px", marginTop: "50px" }}
          >
            <Spot
              balances={userBalances}
              marketData={marketData}
              selectedBasketId={selectedBasketId}
              basketDetails={basketDetails}
              isLoading={isBalanceLoading}
              checkKycStatus={checkKycStatus}
              onTradeResponse={(success) => {
                setTradeSuccess(success);
                setShowTradeResponse(true);
              }}
              isTradeable={selectedBasketId === "1"} // Only TOP5 (id: 1) is tradeable
            />
          </div>
        </div>
        <div className="row g-3 mb-3">
          <div className="col-xxl-12" style={{ padding: "10px" }}>
            <TradeHistory trades={trades} />
          </div>
        </div>
      </div>

      {/* KYC Modal */}
      {showKycModal && (
        <div
          className="modal d-block"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">KYC Verification Required</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowKycModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <p>{kycMessage}</p>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowKycModal(false)}
                >
                  Close
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => (window.location.href = kycRedirectUrl)}
                >
                  Verify Now
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Exchange;
