import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useAuth } from "@clerk/clerk-react";
import { ethers } from "ethers";

function Transaction({ activeTab }) {
  const { getToken } = useAuth();
  const [transactions, setTransactions] = useState([]);

  // Update formatAmount to accept symbol parameter
  const formatAmount = (amount, symbol) => {
    try {
      const regularNumber = Number(amount).toLocaleString("fullwide", {
        useGrouping: false,
      });
      return `${ethers.utils.formatUnits(regularNumber, 18)} ${
        symbol || "BASKET"
      }`;
    } catch (error) {
      console.error("Error formatting amount:", error);
      return "0 BASKET";
    }
  };

  // Custom columns configuration
  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      format: (row) => new Date(row.date).toLocaleString(),
      cell: (row) => (
        <div data-name="Date">{new Date(row.date).toLocaleString()}</div>
      ),
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
      cell: (row) => (
        <div data-name="Type">
          <span
            className={`badge bg-${
              row.type === "Deposit" ? "success" : "warning"
            }`}
          >
            {row.type}
          </span>
        </div>
      ),
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => (
        <div data-name="Amount">{formatAmount(row.amount, row.symbol)}</div>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <div data-name="Status">
          <span
            className={`badge bg-${
              row.status === "COMPLETED" ? "success" : "warning"
            }`}
          >
            {row.status}
          </span>
        </div>
      ),
    },
    {
      name: "Transaction Hash",
      selector: (row) => row.transaction_hash,
      sortable: true,
      cell: (row) => {
        if (!row.transaction_hash) return <div data-name="Tx Hash">N/A</div>;

        const explorerUrl =
          row.chain === "bsc"
            ? `https://bscscan.com/tx/${row.transaction_hash}`
            : `https://redbelly.routescan.io/tx/${row.transaction_hash}`;

        return (
          <div data-name="Tx Hash">
            <a
              href={explorerUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary text-truncate d-inline-block"
              style={{ maxWidth: "150px" }}
            >
              {row.transaction_hash.slice(0, 6)}...
              {row.transaction_hash.slice(-4)}
            </a>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const token = await getToken({ template: "optima" });

        const response = await fetch(
          "https://clownfish-app-x4ynf.ondigitalocean.app/users/transactions",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const result = await response.json();

        if (result.success && result.data?.transfers) {
          const formattedTransactions = result.data.transfers.map(
            (transfer) => ({
              date: transfer.timestamp,
              type: transfer.type === "WITHDRAW" ? "Withdrawal" : "Deposit",
              amount: transfer.amount.raw,
              symbol:
                transfer.token.symbol === "bsc:usdt"
                  ? "BSC-USDT"
                  : transfer.token.symbol,
              status: transfer.status,
              transaction_hash: transfer.txHash,
              fromAddress: transfer.fromAddress,
              toAddress: transfer.toAddress,
              chain: transfer.chain,
            })
          );

          setTransactions(formattedTransactions);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    // Initial fetch
    fetchTransactions();

    // Set up interval for periodic fetching
    const intervalId = setInterval(fetchTransactions, 3000);

    // Cleanup function to clear the interval when component unmounts
    return () => clearInterval(intervalId);
  }, [getToken]);

  const filterTransactions = (transactions) => {
    switch (activeTab) {
      case "deposit":
        return transactions.filter(
          (transaction) => transaction.type === "Deposit"
        );
      case "withdraw":
        return transactions.filter(
          (transaction) => transaction.type === "Withdrawal"
        );
      default: // 'balance' tab or any other
        return transactions; // Show all transactions
    }
  };

  return (
    <div className="container-fluid p-4">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header py-3 d-flex justify-content-between">
            <h6 className="mb-0 fw-bold">Recent Transactions</h6>
          </div>
          <div className="card-body">
            <div className="dataTables_wrapper dt-bootstrap5 no-footer">
              <div className="row">
                <div className="col-sm-12">
                  <DataTable
                    columns={columns}
                    data={filterTransactions(transactions)}
                    defaultSortField="date"
                    defaultSortAsc={false}
                    selectableRows={false}
                    highlightOnHover={true}
                    pagination
                    responsive
                    striped
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transaction;
