import logo from "../../../assets/images/OptimaLogo.png";
import "../../../assets/css/LandingPage/footerv2.css";

export default function Footerv2() {
  const links = [
    { href: "#heroarea", label: "Home" },
    { href: "#feature", label: "Features" },
    { href: "#market", label: "Baskets" },
    { href: "#team", label: "About" },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: "#002041",
        color: "white",
        zIndex: 20,
        padding: "48px 64px",
        gap: "32px",
      }}
    >
      <div
        className="footer-content"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <div
          className="footer-social"
          style={{
            minWidth: "200px",
            flex: "1",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "24px",
              alignItems: "center",
            }}
          >
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "white",
                fontSize: "24px",
                transition: "opacity 0.2s",
                opacity: "0.8",
                ":hover": { opacity: 1 },
              }}
            >
              <span className="fa fa-linkedin"></span>
            </a>
            <a
              href="https://x.com/Optima_Fi"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "white",
                fontSize: "24px",
                transition: "opacity 0.2s",
                opacity: "0.8",
                ":hover": { opacity: 1 },
              }}
            >
              <span className="fa fa-twitter"></span>
            </a>
            <a
              href="https://github.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "white",
                fontSize: "24px",
                transition: "opacity 0.2s",
                opacity: "0.8",
                ":hover": { opacity: 1 },
              }}
            >
              <span className="fa fa-github"></span>
            </a>
          </div>
        </div>

        <div
          className="footer-links"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",
            gap: "8px",
            flex: "2",
            maxWidth: "600px",
            textAlign: "center",
          }}
        >
          {links.map(({ href, label }) => (
            <a
              key={href}
              href={href}
              style={{
                textDecoration: "none",
                color: "#E5E7EB",
                padding: "8px 0",
                transition: "color 0.2s",
                ":hover": {
                  color: "white",
                },
              }}
            >
              <span
                className="footer-link-text"
                style={{
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
                {label}
              </span>
            </a>
          ))}
        </div>

        <div
          className="footer-logo"
          style={{
            minWidth: "200px",
            flex: "1",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="Logo"
            style={{
              height: "60px",
              borderRadius: "8px",
              filter: "brightness(0.95)",
            }}
          />
        </div>
      </div>

      <div
        style={{
          width: "100%",
          textAlign: "center",
          fontSize: "13px",
          color: "#9CA3AF",
          marginTop: "32px",
        }}
      >
        © 2024 Optima Financial • All Rights Reserved
      </div>
    </div>
  );
}
