import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import DataTable from "react-data-table-component";
import {
  AllCripto,
  favoritefutureData,
  favoriteMarginData,
  favoriteSpotData,
  feutureMarketCOIN,
  feutureMarketUSD,
  newListing,
  soptMarkebnb,
  soptMarkeBTC,
  soptMarketALTS,
  soptMarketETF,
  soptMarketFAIT,
} from "../Data/MarketData/MarketData";
import useBasketData from "../../Hooks/useBasket";
import chart from "../../assets/images/total_market_cap.svg";
import axios from "axios";
import { LineChart, Line, ResponsiveContainer } from "recharts";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";

function MarketTabing(props) {
  const baskets = props.baskets.data?.data;
  const [additionalBaskets, setAdditionalBaskets] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isLoaded, isSignedIn } = useAuth();
  const [hourlyPricesData, setHourlyPricesData] = useState({});

  // Add useEffect to fetch additional baskets data
  useEffect(() => {
    const fetchAdditionalBaskets = async () => {
      setLoading(true);
      try {
        // Fetch data for baskets 2-9 (basket 1 is handled by props)
        const basketIds = Array.from({ length: 14 }, (_, i) => i + 2);

        const basketsData = await Promise.all(
          basketIds.map(async (id) => {
            const [overviewResponse, returnsResponse] = await Promise.all([
              axios.get(
                `https://clownfish-app-x4ynf.ondigitalocean.app/baskets/${id}/overview`
              ),
              axios.get(
                `https://clownfish-app-x4ynf.ondigitalocean.app/baskets/${id}/returns`
              ),
            ]);

            const overview = overviewResponse.data.data;
            const returns = returnsResponse.data.data;
            const latestReturn = returns[returns.length - 1];

            // Log returns data for comparison
            console.log(`Basket ${id} - API Returns:`, {
              "1d": latestReturn?.return_1d
                ? (latestReturn.return_1d * 100).toFixed(2) + "%"
                : "-",
              "7d": latestReturn?.return_7d
                ? (latestReturn.return_7d * 100).toFixed(2) + "%"
                : "-",
            });

            return {
              id: overview.id,
              name: overview.name,
              basket_symbol: overview.symbol,
              description: overview.description,
              current: latestReturn?.price || 0,
              Chg1d: latestReturn?.return_1d || 0,
              Chg7d: latestReturn?.return_7d || 0,
              Chg30d: latestReturn?.return_30d || 0,
              returns: returns, // Store full returns data for charts
              isTrading: false,
            };
          })
        );

        setAdditionalBaskets(basketsData);
      } catch (err) {
        setError(err);
        console.error("Error fetching additional baskets:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchAdditionalBaskets();
  }, []);

  useEffect(() => {
    const fetchHourlyPrices = async () => {
      try {
        // Fetch hourly prices for all baskets (1-9)
        const basketIds = Array.from({ length: 15 }, (_, i) => i + 1);
        const hourlyData = {};

        await Promise.all(
          basketIds.map(async (id) => {
            const response = await axios.get(
              `https://clownfish-app-x4ynf.ondigitalocean.app/baskets/${id}/prices/hourly`
            );
            hourlyData[id] = response.data.data.hourlyPrices;
          })
        );

        setHourlyPricesData(hourlyData);
      } catch (err) {
        console.error("Error fetching hourly prices:", err);
      }
    };

    fetchHourlyPrices();
  }, []);

  // Update generateChartData to include original price
  const generateChartData = (basketId) => {
    const hourlyPrices = hourlyPricesData[basketId] || [];
    if (hourlyPrices.length === 0) return [];

    // Take last 7 days of data points
    const last7Days = hourlyPrices.slice(-168);

    // Calculate actual percentage change for verification
    if (last7Days.length >= 2) {
      const firstPrice = last7Days[0].price;
      const lastPrice = last7Days[last7Days.length - 1].price;
      const actualPercentChange = ((lastPrice - firstPrice) / firstPrice) * 100;
      console.log(
        `Basket ${basketId} - Chart Data Change: ${actualPercentChange.toFixed(
          2
        )}%`
      );
    }

    const prices = last7Days.map((dataPoint) => dataPoint.price);
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
    const priceRange = maxPrice - minPrice;

    return last7Days.map((dataPoint, index) => ({
      name: index,
      value: priceRange === 0 ? 0.5 : (dataPoint.price - minPrice) / priceRange,
      originalPrice: dataPoint.price, // Store the original price for trend calculation
    }));
  };

  // Keep SparklineChart component unchanged except for data source
  const SparklineChart = ({ data, performance }) => {
    // Calculate trend based on the 7d return value
    const trend = parseFloat(performance);

    // Use the trend to determine color
    const lineColor =
      data.length === 0
        ? "#888888"
        : trend > 0
        ? "#00C853" // Green for upward trend
        : trend < 0
        ? "#FF3B30" // Red for downward trend
        : "#888888"; // Gray for no change

    return (
      <div
        style={{
          width: "120px",
          height: "40px",
          margin: "0 auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <Line
              type="monotone"
              dataKey="value"
              stroke={lineColor}
              strokeWidth={1.5}
              dot={false}
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  };

  // Update updatedColumns to use dynamic widths based on props
  const updatedColumns = [
    {
      name: "Basket Name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            padding: "8px 0",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              fontSize: "15px",
              fontWeight: "600",
              color: "#002041",
            }}
          >
            {row.name}
          </div>
          <div
            style={{
              fontSize: "13px",
              color: "#666",
              fontWeight: "500",
            }}
          >
            {row.basket_symbol}
          </div>
        </div>
      ),
      width: props.isMarketPage ? "190px" : "210px",
    },
    {
      name: "Current Price",
      selector: (row) => row.current,
      sortable: true,
      cell: (row) => (
        <div
          style={{
            fontSize: "15px",
            fontWeight: "600",
            color: "#002041",
            width: "100%",
            textAlign: "center",
          }}
        >
          {row.current}
        </div>
      ),
      width: props.isMarketPage ? "140px" : "160px",
    },
    {
      name: "1d Return",
      selector: (row) => row.Chg1d,
      sortable: true,
      cell: (row) => (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color:
                row.Chg1d === "-"
                  ? "#666"
                  : parseFloat(row.Chg1d) >= 0
                  ? "#00C853"
                  : "#FF3B30",
              padding: "6px 12px",
              backgroundColor:
                row.Chg1d === "-"
                  ? "transparent"
                  : parseFloat(row.Chg1d) >= 0
                  ? "rgba(0, 200, 83, 0.1)"
                  : "rgba(255, 59, 48, 0.1)",
              borderRadius: "6px",
              display: "inline-block",
              minWidth: "80px",
              textAlign: "center",
            }}
          >
            {row.Chg1d}
          </div>
        </div>
      ),
      width: props.isMarketPage ? "140px" : "160px",
    },
    {
      name: "7d Return",
      selector: (row) => row.Chg7d,
      sortable: true,
      cell: (row) => (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color:
                row.Chg7d === "-"
                  ? "#666"
                  : parseFloat(row.Chg7d) >= 0
                  ? "#00C853"
                  : "#FF3B30",
              padding: "6px 12px",
              backgroundColor:
                row.Chg7d === "-"
                  ? "transparent"
                  : parseFloat(row.Chg7d) >= 0
                  ? "rgba(0, 200, 83, 0.1)"
                  : "rgba(255, 59, 48, 0.1)",
              borderRadius: "6px",
              display: "inline-block",
              minWidth: "80px",
              textAlign: "center",
            }}
          >
            {row.Chg7d}
          </div>
        </div>
      ),
      width: props.isMarketPage ? "140px" : "160px",
    },
    {
      name: "1m Return",
      selector: (row) => row.Chg30d,
      sortable: true,
      cell: (row) => (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color:
                row.Chg30d === "-"
                  ? "#666"
                  : parseFloat(row.Chg30d) >= 0
                  ? "#00C853"
                  : "#FF3B30",
              padding: "6px 12px",
              backgroundColor:
                row.Chg30d === "-"
                  ? "transparent"
                  : parseFloat(row.Chg30d) >= 0
                  ? "rgba(0, 200, 83, 0.1)"
                  : "rgba(255, 59, 48, 0.1)",
              borderRadius: "6px",
              display: "inline-block",
              minWidth: "80px",
              textAlign: "center",
            }}
          >
            {row.Chg30d}
          </div>
        </div>
      ),
      width: props.isMarketPage ? "140px" : "160px",
    },
    {
      name: "Chart (7d)",
      cell: (row) => (
        <div
          style={{
            background: "#f8f9fa",
            padding: "8px",
            borderRadius: "8px",
            width: "170px",
            height: "50px",
            margin: "0 auto",
          }}
        >
          <SparklineChart
            data={generateChartData(row.id)}
            performance={row.Chg7d}
          />
        </div>
      ),
      width: props.isMarketPage ? "170px" : "190px",
    },
    {
      name: "Trade",
      cell: (row) => (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            className="btn btn-primary"
            onClick={() => {
              if (!isLoaded) return;
              if (!isSignedIn) {
                alert("Please login to trade");
                navigate("/sign-in");
              } else {
                navigate(`/exchange?basket=${row.id}`);
              }
            }}
            style={{
              padding: "10px 24px",
              fontSize: "14px",
              fontWeight: "600",
              borderRadius: "8px",
              textTransform: "uppercase",
              letterSpacing: "0.5px",
              backgroundColor: "#002041",
              border: "none",
              cursor: "pointer",
              transition: "all 0.2s ease",
              "&:hover": {
                backgroundColor: "#001631",
                transform: "translateY(-1px)",
              },
            }}
          >
            Trade
          </button>
        </div>
      ),
      button: true,
      width: props.isMarketPage ? "140px" : "160px",
    },
  ];

  // console.log(baskets, "BASKETS");

  const top20 = (data) => {
    // console.log(data.sort((a, b) => b.current - a.current).slice(0, 20));
    return data.sort((a, b) => b.current - a.current).slice(0, 20);
  };

  // Add tableCustomStyles object
  const tableCustomStyles = {
    table: {
      style: {
        backgroundColor: "white",
        borderSpacing: "0 12px",
        borderCollapse: "separate",
      },
    },
    tableWrapper: {
      style: {
        padding: "24px 16px",
      },
    },
    responsiveWrapper: {
      style: {
        borderRadius: "16px",
        backgroundColor: "white",
        boxShadow: "0 4px 24px rgba(0, 0, 0, 0.06)",
        padding: "16px",
        "@media screen and (max-width: 768px)": {
          padding: "0",
          boxShadow: "none",
        },
      },
    },
    headRow: {
      style: {
        backgroundColor: "#f8f9fa",
        borderRadius: "8px",
        border: "none",
        minHeight: "56px",
        "&:hover": {
          backgroundColor: "#f8f9fa",
        },
      },
    },
    rows: {
      style: {
        backgroundColor: "white",
        borderRadius: "12px",
        border: "1px solid #eef0f2",
        marginTop: "12px",
        marginBottom: "12px",
        minHeight: "68px",
        transition: "all 0.2s ease",
        "&:hover": {
          backgroundColor: "#f8f9fa",
          transform: "translateY(-2px)",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
        },
        "@media screen and (max-width: 768px)": {
          display: "none",
        },
      },
      stripedStyle: {
        backgroundColor: "white",
      },
    },
    cells: {
      style: {
        paddingLeft: "24px",
        paddingRight: "24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:first-child": {
          paddingLeft: "32px",
        },
        "&:last-child": {
          paddingRight: "32px",
        },
      },
    },
    headCells: {
      style: {
        fontSize: "13px",
        fontWeight: "600",
        color: "#666",
        textTransform: "uppercase",
        letterSpacing: "0.5px",
        paddingLeft: "24px",
        paddingRight: "24px",
        textAlign: "center",
        "&:first-child": {
          paddingLeft: "32px",
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
        },
        "&:last-child": {
          paddingRight: "32px",
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
        },
      },
    },
    pagination: {
      style: {
        borderTop: "none",
        marginTop: "16px",
      },
      pageButtonsStyle: {
        borderRadius: "8px",
        height: "40px",
        minWidth: "40px",
        padding: "8px",
        margin: "0 4px",
        cursor: "pointer",
        transition: "all 0.2s ease",
        backgroundColor: "transparent",
        border: "1px solid #eef0f2",
        color: "#002041",
        "&:disabled": {
          cursor: "not-allowed",
          color: "#ccc",
          border: "1px solid #eef0f2",
        },
        "&:hover:not(:disabled)": {
          backgroundColor: "#002041",
          color: "white",
          border: "1px solid #002041",
        },
        "&:focus": {
          outline: "none",
          backgroundColor: "#002041",
          color: "white",
          border: "1px solid #002041",
        },
      },
    },
  };

  // First, create a container style
  const containerStyle = {
    position: "relative",
    borderRadius: "20px",
    backgroundColor: "white",
    marginBottom: "20px",
    padding: "24px",
    "@media screen and (max-width: 768px)": {
      padding: "0",
      backgroundColor: "transparent",
    },
  };

  // Add this CSS to your stylesheet
  const scrollbarStyles = `
        /* For Firefox */
        * {
            scrollbar-width: thin;
            scrollbar-color: #888 #f1f1f1;
        }
    `;

  // Keep existing prepareData for the current tradeable basket
  const prepareData = () => {
    const latestData = baskets?.[baskets.length - 1];
    if (!latestData) return [];

    console.log(latestData, "LATEST DATA");

    // Small threshold to consider values as effectively zero
    const ZERO_THRESHOLD = 0.0001;

    // Find basket 14 from additionalBaskets
    const basket14 = additionalBaskets.find((basket) => basket.id === 15);

    return [
      {
        id: 1, // Add id for the chart data
        name: "TOP5",
        basket_symbol: "OP05",
        current: `$${Number(latestData.price).toFixed(2)}`,
        Chg1d:
          latestData.return_1d !== undefined &&
          Math.abs(latestData.return_1d) > ZERO_THRESHOLD
            ? `${(latestData.return_1d * 100).toFixed(2)}%`
            : "-",
        Chg7d:
          latestData.return_7d !== undefined &&
          Math.abs(latestData.return_7d) > ZERO_THRESHOLD
            ? `${(latestData.return_7d * 100).toFixed(2)}%`
            : "-",
        Chg30d:
          latestData.return_30d !== undefined &&
          Math.abs(latestData.return_30d) > ZERO_THRESHOLD
            ? `${(latestData.return_30d * 100).toFixed(2)}%`
            : "-",
        isTrading: true,
      },
      basket14
        ? {
            id: 14,
            name: basket14.name,
            basket_symbol: basket14.basket_symbol,
            current: `$${Number(basket14.current).toFixed(2)}`,
            Chg1d:
              basket14.Chg1d !== undefined &&
              Math.abs(basket14.Chg1d) > ZERO_THRESHOLD
                ? `${(basket14.Chg1d * 100).toFixed(2)}%`
                : "-",
            Chg7d:
              basket14.Chg7d !== undefined &&
              Math.abs(basket14.Chg7d) > ZERO_THRESHOLD
                ? `${(basket14.Chg7d * 100).toFixed(2)}%`
                : "-",
            Chg30d:
              basket14.Chg30d !== undefined &&
              Math.abs(basket14.Chg30d) > ZERO_THRESHOLD
                ? `${(basket14.Chg30d * 100).toFixed(2)}%`
                : "-",
            isTrading: false,
          }
        : null,
    ].filter(Boolean); // Remove null entries if basket14 is not found
  };

  // Update prepareThematicData to use fetched data
  const prepareThematicData = () => {
    // Small threshold to consider values as effectively zero
    const ZERO_THRESHOLD = 0.0001;

    return additionalBaskets
      .filter((basket) => basket.id !== 15) // Exclude basket 14
      .map((basket) => ({
        id: basket.id,
        name: basket.name,
        basket_symbol: basket.basket_symbol,
        current: `$${Number(basket.current).toFixed(2)}`,
        Chg1d:
          basket.Chg1d !== undefined && Math.abs(basket.Chg1d) > ZERO_THRESHOLD
            ? `${(basket.Chg1d * 100).toFixed(2)}%`
            : "-",
        Chg7d:
          basket.Chg7d !== undefined && Math.abs(basket.Chg7d) > ZERO_THRESHOLD
            ? `${(basket.Chg7d * 100).toFixed(2)}%`
            : "-",
        Chg30d:
          basket.Chg30d !== undefined &&
          Math.abs(basket.Chg30d) > ZERO_THRESHOLD
            ? `${(basket.Chg30d * 100).toFixed(2)}%`
            : "-",
        isTrading: false,
      }));
  };

  // Combine all data for the "All" section
  const prepareAllData = () => {
    return [...prepareData(), ...prepareThematicData()];
  };

  // Add this new component for mobile card view
  const MobileCard = ({ row }) => {
    return (
      <div className="mobile-card">
        <style>
          {`
            .mobile-card {
              padding: 20px;
              background: white;
              border-radius: 16px;
              box-shadow: 0 4px 12px rgba(0,0,0,0.08);
              margin: 12px 8px;
              border: 1px solid #eef0f2;
            }
            .mobile-card-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 16px;
              padding-bottom: 12px;
              border-bottom: 1px solid #eef0f2;
            }
            .mobile-card-title {
              font-size: 18px;
              font-weight: 600;
              color: #002041;
            }
            .mobile-card-symbol {
              font-size: 14px;
              color: #666;
              margin-top: 4px;
            }
            .mobile-card-price {
              font-size: 24px;
              font-weight: 700;
              color: #002041;
            }
            .mobile-card-stats {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              gap: 12px;
              margin: 16px 0;
            }
            .mobile-card-stat {
              text-align: center;
              padding: 12px 8px;
              background: #f8f9fa;
              border-radius: 12px;
              border: 1px solid #eef0f2;
            }
            .mobile-card-stat-label {
              font-size: 12px;
              color: #666;
              margin-bottom: 6px;
              font-weight: 500;
            }
            .mobile-card-stat-value {
              font-size: 16px;
              font-weight: 600;
            }
            .mobile-card-chart {
              margin: 16px 0;
              height: 80px;
              background: #f8f9fa;
              border-radius: 12px;
              padding: 12px;
            }
            .mobile-card-action {
              margin-top: 16px;
            }
            .mobile-trade-button {
              padding: 14px;
              font-size: 16px;
              font-weight: 600;
              border-radius: 12px;
              text-transform: uppercase;
              letter-spacing: 0.5px;
              background-color: #002041;
              color: white;
              border: none;
              cursor: pointer;
              width: 100%;
              transition: all 0.2s ease;
            }
            .mobile-trade-button:active {
              transform: scale(0.98);
            }
            .mobile-cards-container {
              padding: 8px;
            }
            @media (hover: hover) {
              .mobile-card {
                transition: all 0.2s ease;
              }
              .mobile-card:hover {
                transform: translateY(-2px);
                box-shadow: 0 6px 16px rgba(0,0,0,0.12);
              }
              .mobile-trade-button:hover {
                background-color: #001631;
              }
            }
          `}
        </style>
        <div className="mobile-card-header">
          <div>
            <div className="mobile-card-title">{row.name}</div>
            <div className="mobile-card-symbol">{row.basket_symbol}</div>
          </div>
          <div className="mobile-card-price">{row.current}</div>
        </div>
        <div className="mobile-card-stats">
          <div className="mobile-card-stat">
            <div className="mobile-card-stat-label">1d Change</div>
            <div
              className="mobile-card-stat-value"
              style={{
                color:
                  row.Chg1d === "-"
                    ? "inherit"
                    : parseFloat(row.Chg1d) >= 0
                    ? "#00C853"
                    : "#FF3B30",
              }}
            >
              {row.Chg1d}
            </div>
          </div>
          <div className="mobile-card-stat">
            <div className="mobile-card-stat-label">7d Change</div>
            <div
              className="mobile-card-stat-value"
              style={{
                color:
                  row.Chg7d === "-"
                    ? "inherit"
                    : parseFloat(row.Chg7d) >= 0
                    ? "#00C853"
                    : "#FF3B30",
              }}
            >
              {row.Chg7d}
            </div>
          </div>
          <div className="mobile-card-stat">
            <div className="mobile-card-stat-label">30d Change</div>
            <div
              className="mobile-card-stat-value"
              style={{
                color:
                  row.Chg30d === "-"
                    ? "inherit"
                    : parseFloat(row.Chg30d) >= 0
                    ? "#00C853"
                    : "#FF3B30",
              }}
            >
              {row.Chg30d}
            </div>
          </div>
        </div>
        <div className="mobile-card-chart">
          <SparklineChart
            data={generateChartData(row.id)}
            performance={row.Chg7d}
          />
        </div>
        <div className="mobile-card-action">
          <button
            className="mobile-trade-button"
            onClick={() => {
              if (!isLoaded) return;
              if (!isSignedIn) {
                alert("Please login to trade");
                navigate("/sign-in");
              } else {
                navigate(`/exchange?basket=${row.id}`);
              }
            }}
          >
            Trade Now
          </button>
        </div>
      </div>
    );
  };

  // Add global styles for mobile responsiveness
  const GlobalStyles = () => (
    <style>
      {`
        @media screen and (max-width: 768px) {
          .nav-pills {
            width: 100%;
            overflow-x: auto;
            white-space: nowrap;
            -webkit-overflow-scrolling: touch;
            padding: 8px 4px;
          }
          .nav-item {
            display: inline-block;
            margin-right: 8px;
          }
          .nav-link {
            padding: 8px 16px;
            border-radius: 20px !important;
            font-size: 14px;
            font-weight: 500;
          }
          .tab-content {
            padding: 0 4px;
          }
          .dataTables_filter {
            margin: 12px 8px;
          }
          .dataTables_filter input {
            width: 100%;
            padding: 8px 12px;
            border-radius: 8px;
            border: 1px solid #eef0f2;
          }
        }
      `}
    </style>
  );

  return (
    <>
      <GlobalStyles />
      <Tab.Container defaultActiveKey="first">
        <div className="row g-3 my-5 pb-4"></div>
        <div className="row g-3 mb-3">
          <div className="col-lg-12 col-md-12">
            <Tab.Content className="tab-content">
              <Tab.Pane
                className="tab-pane fade"
                eventKey="first"
                id="Favorites"
              >
                <Tab.Container defaultActiveKey="first">
                  <Nav
                    className="nav nav-pills rounded d-inline-flex"
                    role="tablist"
                  >
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        className="nav-link"
                        eventKey="first"
                        data-bs-toggle="tab"
                        href="#All"
                        role="tab"
                      >
                        All
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        className="nav-link"
                        eventKey="second"
                        data-bs-toggle="tab"
                        href="#General"
                        role="tab"
                      >
                        General
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        className="nav-link"
                        eventKey="third"
                        data-bs-toggle="tab"
                        href="#Thematic"
                        role="tab"
                      >
                        Thematic
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item className="nav-item">
                      <Nav.Link
                        className="nav-link"
                        eventKey="fourth"
                        data-bs-toggle="tab"
                        href="#RiskAdjusted"
                        role="tab"
                      >
                        Risk Adjusted
                      </Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        className="nav-link"
                        eventKey="fifth"
                        data-bs-toggle="tab"
                        href="#RWA"
                        role="tab"
                      >
                        RWA
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content className="tab-content mt-4">
                    <Tab.Pane
                      className="tab-pane fade"
                      eventKey="first"
                      id="Spot"
                    >
                      <div className="d-none d-md-block">
                        <DataTable
                          title={
                            prepareAllData().length > 0 ? "All Baskets" : ""
                          }
                          columns={updatedColumns}
                          data={prepareAllData()}
                          defaultSortField="name"
                          selectableRows={false}
                          highlightOnHover={true}
                          customStyles={tableCustomStyles}
                          responsive
                        />
                      </div>
                      <div className="d-md-none">
                        <div className="mobile-cards-container">
                          {prepareAllData().map((row, index) => (
                            <MobileCard key={index} row={row} />
                          ))}
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      className="tab-pane fade"
                      eventKey="second"
                      id="General"
                    >
                      <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper dt-bootstrap5 no-footer"
                      >
                        {/* <div className="row">
                          <div
                            className="col-sm-12 col-md-6 mb-2 "
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              id="myDataTable_filter"
                              className="dataTables_filter"
                            >
                              <label className="d-flex align-items-center">
                                Search:
                                <div className="mx-1">
                                  <input
                                    type="search"
                                    className="form-control"
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                        </div> */}
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="d-none d-md-block">
                              <DataTable
                                title={"General Baskets"}
                                columns={updatedColumns}
                                data={prepareData()}
                                defaultSortField="name"
                                selectableRows={false}
                                highlightOnHover={true}
                                customStyles={tableCustomStyles}
                                responsive
                              />
                            </div>
                            <div className="d-md-none">
                              <div className="mobile-cards-container">
                                {prepareData().map((row, index) => (
                                  <MobileCard key={index} row={row} />
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      className="tab-pane fade"
                      eventKey="third"
                      id="Thematic"
                    >
                      <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper dt-bootstrap5 no-footer"
                      >
                        {/* <div className="row">
                          <div
                            className="col-sm-12 col-md-6 mb-2 "
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              id="myDataTable_filter"
                              className="dataTables_filter"
                            >
                              <label className="d-flex align-items-center">
                                Search:
                                <div className="mx-1">
                                  <input
                                    type="search"
                                    className="form-control"
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                        </div> */}
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="d-none d-md-block">
                              <DataTable
                                title={"Thematic Baskets"}
                                columns={updatedColumns}
                                data={prepareThematicData()}
                                defaultSortField="name"
                                selectableRows={false}
                                highlightOnHover={true}
                                customStyles={tableCustomStyles}
                                responsive
                              />
                            </div>
                            <div className="d-md-none">
                              <div className="mobile-cards-container">
                                {prepareThematicData().map((row, index) => (
                                  <MobileCard key={index} row={row} />
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      className="tab-pane fade"
                      eventKey="fourth"
                      id="Margin"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "200px",
                          fontSize: "32px",
                          fontWeight: "bold",
                          color: "#002041",
                          background: "white",
                          borderRadius: "10px",
                          padding: "20px",
                          margin: "20px 0",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          letterSpacing: "2px",
                        }}
                      >
                        Coming Soon
                      </div>
                      {/*<div id="DataTables_Table_2_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                                <div className="row"><div className="col-sm-12 col-md-6 mb-2 " style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div id="myDataTable_filter" className="dataTables_filter">
                                                        <label className='d-flex align-items-center'>Search:<div className='mx-1'><input type="search" className="form-control" /></div></label>
                                                    </div>
                                                </div></div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <DataTable
                                                            title={favoriteMarginData.title}
                                                            columns={favoriteMarginData.columns}
                                                            data={favoriteMarginData.rows}
                                                            defaultSortField="title"
                                                            selectableRows={false}
                                                            highlightOnHover={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3 ">
                                                    <div className="col-sm-12 col-md-5" style={{ float: 'left' }}>
                                                        <div className="dataTables_info" id="myDataTable_info" role="status" aria-live="polite">Showing 1 to 7 of 7 entries</div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-7 " >
                                                        <div className="dataTables_paginate paging_simple_numbers" id="myDataTable_paginate" style={{ float: 'right' }}>
                                                            <ul className="pagination">
                                                                <li className="paginate_button page-item previous disabled" id="myDataTable_previous"><a href="#!" className="page-link">Previous</a></li>
                                                                <li className="paginate_button page-item active"><a href="#!" className="page-link">1</a></li>
                                                                <li className="paginate_button page-item next disabled" id="myDataTable_next"><a href="#!" className="page-link">Next</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>*/}
                    </Tab.Pane>
                    <Tab.Pane
                      className="tab-pane fade"
                      eventKey="fifth"
                      id="Spot"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "200px",
                          fontSize: "32px",
                          fontWeight: "bold",
                          color: "#002041",
                          background: "white",
                          borderRadius: "10px",
                          padding: "20px",
                          margin: "20px 0",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          letterSpacing: "2px",
                        }}
                      >
                        Real World Assets || Coming Soon
                      </div>
                      {/*<div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                                <div className="row"><div className="col-sm-12 col-md-6 mb-2 " style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div id="myDataTable_filter" className="dataTables_filter">
                                                        <label className='d-flex align-items-center'>Search:<div className='mx-1'><input type="search" className="form-control" /></div></label>
                                                    </div>
                                                </div></div>
                                                <div className="row"><div className="col-sm-12">
                                                    <DataTable
                                                        title={favoriteSpotData.title}
                                                        columns={favoriteSpotData.columns}
                                                        data={favoriteSpotData.rows}
                                                        defaultSortField="title"
                                                        selectableRows={false}
                                                        highlightOnHover={true}
                                                    />
                                                </div>
                                                </div>
                                                <div className="row mt-3 ">
                                                    <div className="col-sm-12 col-md-5" style={{ float: 'left' }}>
                                                        <div className="dataTables_info" id="myDataTable_info" role="status" aria-live="polite">Showing 1 to 7 of 7 entries</div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-7 " >
                                                        <div className="dataTables_paginate paging_simple_numbers" id="myDataTable_paginate" style={{ float: 'right' }}>
                                                            <ul className="pagination">
                                                                <li className="paginate_button page-item previous disabled" id="myDataTable_previous"><a href="#!" className="page-link">Previous</a></li>
                                                                <li className="paginate_button page-item active"><a href="#!" className="page-link">1</a></li>
                                                                <li className="paginate_button page-item next disabled" id="myDataTable_next"><a href="#!" className="page-link">Next</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>*/}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Tab.Pane>
              <Tab.Pane
                className="tab-pane fade"
                eventKey="second"
                id="Spotmarkets"
              >
                <Tab.Container defaultActiveKey="first">
                  <Nav
                    className="nav nav-pills rounded d-inline-flex"
                    role="tablist"
                  >
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        className="nav-link "
                        eventKey="first"
                        data-bs-toggle="tab"
                        href="#BNB"
                        role="tab"
                      >
                        BNB
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        className="nav-link"
                        eventKey="second"
                        data-bs-toggle="tab"
                        href="#BTC"
                        role="tab"
                      >
                        BTC
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        className="nav-link"
                        eventKey="third"
                        data-bs-toggle="tab"
                        href="#ALTS"
                        role="tab"
                      >
                        ALTS
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        className="nav-link"
                        eventKey="fourth"
                        data-bs-toggle="tab"
                        href="#FIAT"
                        role="tab"
                      >
                        FIAT
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        className="nav-link"
                        eventKey="fifth"
                        data-bs-toggle="tab"
                        href="#ETF"
                        role="tab"
                      >
                        ETF
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content className="tab-content mt-4">
                    <Tab.Pane
                      className="tab-pane fade"
                      eventKey="first"
                      id="BNB"
                    >
                      <div
                        id="DataTables_Table_3_wrapper"
                        className="dataTables_wrapper dt-bootstrap5 no-footer"
                      >
                        <div className="row">
                          <div
                            className="col-sm-12 col-md-6 mb-2 "
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              id="myDataTable_filter"
                              className="dataTables_filter"
                            >
                              <label className="d-flex align-items-center">
                                Search:
                                <div className="mx-1">
                                  <input
                                    type="search"
                                    className="form-control"
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="d-none d-md-block">
                              <DataTable
                                title={soptMarkebnb.title}
                                columns={soptMarkebnb.columns}
                                data={soptMarkebnb.rows}
                                defaultSortField="title"
                                selectableRows={false}
                                highlightOnHover={true}
                                customStyles={tableCustomStyles}
                                responsive
                              />
                            </div>
                            <div className="d-md-none">
                              <div className="mobile-cards-container">
                                {soptMarkebnb.rows.map((row, index) => (
                                  <MobileCard key={index} row={row} />
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      className="tab-pane fade"
                      eventKey="second"
                      id="BTC"
                    >
                      <div
                        id="DataTables_Table_4_wrapper"
                        className="dataTables_wrapper dt-bootstrap5 no-footer"
                      >
                        <div className="row">
                          <div
                            className="col-sm-12 col-md-6 mb-2 "
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              id="myDataTable_filter"
                              className="dataTables_filter"
                            >
                              <label className="d-flex align-items-center">
                                Search:
                                <div className="mx-1">
                                  <input
                                    type="search"
                                    className="form-control"
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <DataTable
                              title={soptMarkeBTC.title}
                              columns={soptMarkeBTC.columns}
                              data={soptMarkeBTC.rows}
                              defaultSortField="title"
                              selectableRows={false}
                              highlightOnHover={true}
                              customStyles={tableCustomStyles}
                            />
                          </div>
                        </div>

                        <div className="row mt-3 ">
                          <div
                            className="col-sm-12 col-md-5"
                            style={{ float: "left" }}
                          >
                            <div
                              className="dataTables_info"
                              id="myDataTable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing 1 to 7 of 7 entries
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-7 ">
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="myDataTable_paginate"
                              style={{ float: "right" }}
                            >
                              <ul className="pagination">
                                <li
                                  className="paginate_button page-item previous disabled"
                                  id="myDataTable_previous"
                                >
                                  <a href="#!" className="page-link">
                                    Previous
                                  </a>
                                </li>
                                <li className="paginate_button page-item active">
                                  <a href="#!" className="page-link">
                                    1
                                  </a>
                                </li>
                                <li
                                  className="paginate_button page-item next disabled"
                                  id="myDataTable_next"
                                >
                                  <a href="#!" className="page-link">
                                    Next
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      className="tab-pane fade"
                      eventKey="third"
                      id="ALTS"
                    >
                      <div
                        id="DataTables_Table_5_wrapper"
                        className="dataTables_wrapper dt-bootstrap5 no-footer"
                      >
                        <div className="row">
                          <div
                            className="col-sm-12 col-md-6 mb-2 "
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              id="myDataTable_filter"
                              className="dataTables_filter"
                            >
                              <label className="d-flex align-items-center">
                                Search:
                                <div className="mx-1">
                                  <input
                                    type="search"
                                    className="form-control"
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <DataTable
                              title={soptMarketALTS.title}
                              columns={soptMarketALTS.columns}
                              data={soptMarketALTS.rows}
                              defaultSortField="title"
                              selectableRows={false}
                              highlightOnHover={true}
                              customStyles={tableCustomStyles}
                            />
                          </div>
                        </div>
                        <div className="row mt-3 ">
                          <div
                            className="col-sm-12 col-md-5"
                            style={{ float: "left" }}
                          >
                            <div
                              className="dataTables_info"
                              id="myDataTable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing 1 to 7 of 7 entries
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-7 ">
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="myDataTable_paginate"
                              style={{ float: "right" }}
                            >
                              <ul className="pagination">
                                <li
                                  className="paginate_button page-item previous disabled"
                                  id="myDataTable_previous"
                                >
                                  <a href="#!" className="page-link">
                                    Previous
                                  </a>
                                </li>
                                <li className="paginate_button page-item active">
                                  <a href="#!" className="page-link">
                                    1
                                  </a>
                                </li>
                                <li
                                  className="paginate_button page-item next disabled"
                                  id="myDataTable_next"
                                >
                                  <a href="#!" className="page-link">
                                    Next
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      className="tab-pane fade"
                      eventKey="fourth"
                      id="FIAT"
                    >
                      <div
                        id="DataTables_Table_6_wrapper"
                        className="dataTables_wrapper dt-bootstrap5 no-footer"
                      >
                        <div className="row">
                          <div
                            className="col-sm-12 col-md-6 mb-2 "
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              id="myDataTable_filter"
                              className="dataTables_filter"
                            >
                              <label className="d-flex align-items-center">
                                Search:
                                <div className="mx-1">
                                  <input
                                    type="search"
                                    className="form-control"
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <DataTable
                              title={soptMarketFAIT.title}
                              columns={soptMarketFAIT.columns}
                              data={soptMarketFAIT.rows}
                              defaultSortField="title"
                              selectableRows={false}
                              highlightOnHover={true}
                              customStyles={tableCustomStyles}
                            />
                          </div>
                        </div>
                        <div className="row mt-3 ">
                          <div
                            className="col-sm-12 col-md-5"
                            style={{ float: "left" }}
                          >
                            <div
                              className="dataTables_info"
                              id="myDataTable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing 1 to 7 of 7 entries
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-7 ">
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="myDataTable_paginate"
                              style={{ float: "right" }}
                            >
                              <ul className="pagination">
                                <li
                                  className="paginate_button page-item previous disabled"
                                  id="myDataTable_previous"
                                >
                                  <a href="#!" className="page-link">
                                    Previous
                                  </a>
                                </li>
                                <li className="paginate_button page-item active">
                                  <a href="#!" className="page-link">
                                    1
                                  </a>
                                </li>
                                <li
                                  className="paginate_button page-item next disabled"
                                  id="myDataTable_next"
                                >
                                  <a href="#!" className="page-link">
                                    Next
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      className="tab-pane fade"
                      eventKey="fifth"
                      id="ETF"
                    >
                      <div
                        id="DataTables_Table_7_wrapper"
                        className="dataTables_wrapper dt-bootstrap5 no-footer"
                      >
                        <div className="row">
                          <div
                            className="col-sm-12 col-md-6 mb-2 "
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              id="myDataTable_filter"
                              className="dataTables_filter"
                            >
                              <label className="d-flex align-items-center">
                                Search:
                                <div className="mx-1">
                                  <input
                                    type="search"
                                    className="form-control"
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <DataTable
                              title={soptMarketETF.title}
                              columns={soptMarketETF.columns}
                              data={soptMarketETF.rows}
                              defaultSortField="title"
                              selectableRows={false}
                              highlightOnHover={true}
                              customStyles={tableCustomStyles}
                            />
                          </div>
                        </div>
                        <div className="row mt-3 ">
                          <div
                            className="col-sm-12 col-md-5"
                            style={{ float: "left" }}
                          >
                            <div
                              className="dataTables_info"
                              id="myDataTable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing 1 to 7 of 7 entries
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-7 ">
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="myDataTable_paginate"
                              style={{ float: "right" }}
                            >
                              <ul className="pagination">
                                <li
                                  className="paginate_button page-item previous disabled"
                                  id="myDataTable_previous"
                                >
                                  <a href="#!" className="page-link">
                                    Previous
                                  </a>
                                </li>
                                <li className="paginate_button page-item active">
                                  <a href="#!" className="page-link">
                                    1
                                  </a>
                                </li>
                                <li
                                  className="paginate_button page-item next disabled"
                                  id="myDataTable_next"
                                >
                                  <a href="#!" className="page-link">
                                    Next
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Tab.Pane>
              <Tab.Pane
                className="tab-pane fade"
                eventKey="third"
                id="Futuresmarkets"
              >
                <Tab.Container defaultActiveKey="first">
                  <Nav
                    className="nav nav-pills rounded d-inline-flex"
                    role="tablist"
                  >
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        className="nav-link "
                        eventKey="first"
                        data-bs-toggle="tab"
                        href="#USDFUTURES"
                        role="tab"
                      >
                        USDⓈ-M Futures
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        className="nav-link"
                        eventKey="second"
                        data-bs-toggle="tab"
                        href="#COINFUTURE"
                        role="tab"
                      >
                        COIN-M Futures
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content className="tab-content mt-4">
                    <Tab.Pane
                      className="tab-pane fade"
                      eventKey="first"
                      id="USDFUTURES"
                    >
                      <div
                        id="DataTables_Table_8_wrapper"
                        className="dataTables_wrapper dt-bootstrap5 no-footer"
                      >
                        <div className="row">
                          <div
                            className="col-sm-12 col-md-6 mb-2 "
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              id="myDataTable_filter"
                              className="dataTables_filter"
                            >
                              <label className="d-flex align-items-center">
                                Search:
                                <div className="mx-1">
                                  <input
                                    type="search"
                                    className="form-control"
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <DataTable
                              title={feutureMarketUSD.title}
                              columns={feutureMarketUSD.columns}
                              data={feutureMarketUSD.rows}
                              defaultSortField="title"
                              selectableRows={false}
                              highlightOnHover={true}
                              customStyles={tableCustomStyles}
                            />
                          </div>
                        </div>
                        <div className="row mt-3 ">
                          <div
                            className="col-sm-12 col-md-5"
                            style={{ float: "left" }}
                          >
                            <div
                              className="dataTables_info"
                              id="myDataTable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing 1 to 7 of 7 entries
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-7 ">
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="myDataTable_paginate"
                              style={{ float: "right" }}
                            >
                              <ul className="pagination">
                                <li
                                  className="paginate_button page-item previous disabled"
                                  id="myDataTable_previous"
                                >
                                  <a href="#!" className="page-link">
                                    Previous
                                  </a>
                                </li>
                                <li className="paginate_button page-item active">
                                  <a href="#!" className="page-link">
                                    1
                                  </a>
                                </li>
                                <li
                                  className="paginate_button page-item next disabled"
                                  id="myDataTable_next"
                                >
                                  <a href="#!" className="page-link">
                                    Next
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      className="tab-pane fade"
                      eventKey="second"
                      id="COINFUTURE"
                    >
                      <div
                        id="DataTables_Table_9_wrapper"
                        className="dataTables_wrapper dt-bootstrap5 no-footer"
                      >
                        <div className="row">
                          <div
                            className="col-sm-12 col-md-6 mb-2 "
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              id="myDataTable_filter"
                              className="dataTables_filter"
                            >
                              <label className="d-flex align-items-center">
                                Search:
                                <div className="mx-1">
                                  <input
                                    type="search"
                                    className="form-control"
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <DataTable
                              title={feutureMarketCOIN.title}
                              columns={feutureMarketCOIN.columns}
                              data={feutureMarketCOIN.rows}
                              defaultSortField="title"
                              selectableRows={false}
                              highlightOnHover={true}
                              customStyles={tableCustomStyles}
                            />
                          </div>
                        </div>
                        <div className="row mt-3 ">
                          <div
                            className="col-sm-12 col-md-5"
                            style={{ float: "left" }}
                          >
                            <div
                              className="dataTables_info"
                              id="myDataTable_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing 1 to 7 of 7 entries
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-7 ">
                            <div
                              className="dataTables_paginate paging_simple_numbers"
                              id="myDataTable_paginate"
                              style={{ float: "right" }}
                            >
                              <ul className="pagination">
                                <li
                                  className="paginate_button page-item previous disabled"
                                  id="myDataTable_previous"
                                >
                                  <a href="#!" className="page-link">
                                    Previous
                                  </a>
                                </li>
                                <li className="paginate_button page-item active">
                                  <a href="#!" className="page-link">
                                    1
                                  </a>
                                </li>
                                <li
                                  className="paginate_button page-item next disabled"
                                  id="myDataTable_next"
                                >
                                  <a href="#!" className="page-link">
                                    Next
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Tab.Pane>
              <Tab.Pane
                className="tab-pane fade"
                eventKey="fourth"
                id="Newlisting"
              >
                <div
                  id="DataTables_Table_10_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <div className="row">
                    <div
                      className="col-sm-12 col-md-6 mb-2 "
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div
                        id="myDataTable_filter"
                        className="dataTables_filter"
                      >
                        <label className="d-flex align-items-center">
                          Search:
                          <div className="mx-1">
                            <input type="search" className="form-control" />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <DataTable
                        title={newListing.title}
                        columns={newListing.columns}
                        data={newListing.rows}
                        defaultSortField="title"
                        selectableRows={false}
                        highlightOnHover={true}
                        customStyles={tableCustomStyles}
                      />
                    </div>
                  </div>
                  <div className="row mt-3 ">
                    <div
                      className="col-sm-12 col-md-5"
                      style={{ float: "left" }}
                    >
                      <div
                        className="dataTables_info"
                        id="myDataTable_info"
                        role="status"
                        aria-live="polite"
                      >
                        Showing 1 to 7 of 7 entries
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-7 ">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="myDataTable_paginate"
                        style={{ float: "right" }}
                      >
                        <ul className="pagination">
                          <li
                            className="paginate_button page-item previous disabled"
                            id="myDataTable_previous"
                          >
                            <a href="#!" className="page-link">
                              Previous
                            </a>
                          </li>
                          <li className="paginate_button page-item active">
                            <a href="#!" className="page-link">
                              1
                            </a>
                          </li>
                          <li
                            className="paginate_button page-item next disabled"
                            id="myDataTable_next"
                          >
                            <a href="#!" className="page-link">
                              Next
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane
                className="tab-pane fade"
                eventKey="fifth"
                id="Allcryptos"
              >
                <div
                  id="DataTables_Table_11_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <div className="row">
                    <div
                      className="col-sm-12 col-md-6 mb-2 "
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div
                        id="myDataTable_filter"
                        className="dataTables_filter"
                      >
                        <label className="d-flex align-items-center">
                          Search:
                          <div className="mx-1">
                            <input type="search" className="form-control" />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <DataTable
                        title={AllCripto.title}
                        columns={AllCripto.columns}
                        data={AllCripto.rows}
                        defaultSortField="title"
                        selectableRows={false}
                        highlightOnHover={true}
                        customStyles={tableCustomStyles}
                      />
                    </div>
                  </div>
                  <div className="row mt-3 ">
                    <div
                      className="col-sm-12 col-md-5"
                      style={{ float: "left" }}
                    >
                      <div
                        className="dataTables_info"
                        id="myDataTable_info"
                        role="status"
                        aria-live="polite"
                      >
                        Showing 1 to 7 of 7 entries
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-7 ">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="myDataTable_paginate"
                        style={{ float: "right" }}
                      >
                        <ul className="pagination">
                          <li
                            className="paginate_button page-item previous disabled"
                            id="myDataTable_previous"
                          >
                            <a href="#!" className="page-link">
                              Previous
                            </a>
                          </li>
                          <li className="paginate_button page-item active">
                            <a href="#!" className="page-link">
                              1
                            </a>
                          </li>
                          <li
                            className="paginate_button page-item next disabled"
                            id="myDataTable_next"
                          >
                            <a href="#!" className="page-link">
                              Next
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </>
  );
}
export default MarketTabing;
