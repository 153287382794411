import React, { useState, useEffect } from "react";
import PageTitle from "../../Component/Comman/PageTitle";
import { marketCardData } from "../../Component/Data/MarketData/MarketData";
import MarketTabing from "../../Component/Market/MarketTabing";
import TopSmallCard from "../../Component/Market/TopSmallCard";
import MarketTabingv2 from "../../Component/Market/MarketTabingv2";
import axios from "axios";
// Baskets Page
function Market() {
  const [baskets, setBaskets] = useState([]);

  useEffect(() => {
    const fetchBasketData = async () => {
      const response = await axios.get(
        "https://clownfish-app-x4ynf.ondigitalocean.app/baskets/1/returns"
      );
      setBaskets(response);
    };

    fetchBasketData();
  }, []);

  return (
    <>
      <div className="body-header border-bottom d-flex py-4 mb-3">
        <h4 className="m-0" style={{ color: "#002040FF" }}>
          Baskets
        </h4>
      </div>
      <div className="container-xxl" style={{ marginTop: "-110px" }}>
        <MarketTabingv2 baskets={baskets} isMarketPage={true} />
      </div>
    </>
  );
}

export default Market;
